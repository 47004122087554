import {action, computed, observable} from "mobx";

export default class CardStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable selectedCard = null;

    @action setCard = (card) => {
        // console.log("set card: ", card.id)
        this.selectedCard = card;
    };

    @action clearCard = () => {
        this.selectedCard = null;
    };

    @computed get currentCard() {
        return this.selectedCard;
    };


    @observable companyList = [];

    @action setCompanyList = companyList => {
        this.companyList = companyList;
    };

    @computed get savedCompanyList() {
        return this.companyList;
    }

    @action findCompany = companyId => {
        for (const company of this.companyList) {
            if (company.id === companyId) {
                return company;
            }
        }
        return null;
    }
}
