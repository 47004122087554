import path from 'path';

const urls = {
    URI_CUSTOMER_BY_CUSTOMER_ID : '/pos/data/customer_by_customer_id',
    URI_CUSTOMER_BY_ID : '/pos/data/customer',
};
class api {
    static uriCustomerByID(id) {
        return path.join(urls.URI_CUSTOMER_BY_ID, id);
    }

    static uriCustomerByCustomerID(customer_id) {
        return path.join(urls.URI_CUSTOMER_BY_CUSTOMER_ID, customer_id);
    }
}
api.urls = urls;

export default api;
