import React from "react";
import styles from "./index.module.less";
import {Button, Dropdown, Menu, Row} from "antd";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";

export default class PageTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedKey: null, //config.SUBMENU_ITEMS.MY_CARDS.MY_CARDS,
        };
        this.tabs = this.props.tabs ? this.props.tabs : [];
        this.defaultSelectd = this.tabs[0].index;
    };

    componentDidMount() {
        this.setState({selectedKey: this.defaultSelectd})
    }

    getClassName = index => {
        const {selectedKey} = this.state;
        if (index === selectedKey) {
            return styles.fakeMenuItem;
        } else {
            return styles.fakeMenuItemUnselected;
        }
    };

    changeMenu = value => {
        this.setState({selectedKey: value})
    };

    getComponentToRender = () => {
        for (const tab of this.tabs) {
            if (tab.index === this.state.selectedKey) {
                return tab.component;
            }
        }
    };

    getDropdownMenu = () => {
        return (
            <Menu
                onClick={e => this.changeMenu(e.key)}
                selectedKeys={[this.state.selectedKey]}
                defaultSelectedKeys={[this.state.selectedKey]}
            >
                {
                    this.tabs.map(tab => {
                        return (
                            <Menu.Item key={tab.index} value={tab.index}>
                                {tab.tabTitle}
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        )
    }

    getSelectedMenuTitle = () => {
        for (const tab of this.tabs) {
            if (tab.index === this.state.selectedKey) {
                return tab.tabTitle;
            }
        }
    };

    render() {
        return (
            <div className={styles.wrap}>
                <Row className={styles.fakeMenu}>
                    {
                        this.tabs.map(tab => {
                            return (
                                <div
                                    key={tab.index}
                                    className={this.getClassName(tab.index)}
                                    onClick={() => this.changeMenu(tab.index)}
                                >
                                    {tab.tabTitle}
                                </div>
                            )
                        })
                    }
                    {
                        <Dropdown className={styles.dropdownMenu} overlay={this.getDropdownMenu()}>
                            <Button>
                                {this.getSelectedMenuTitle()}
                                <DownOutlined className={styles.dropdownMenuIcon}/>
                            </Button>
                        </Dropdown>
                    }
                </Row>
                <Row>

                </Row>
                <Row className={styles.main}>
                    {
                        this.getComponentToRender()
                    }
                </Row>
                <Row className={styles.footage}>
                </Row>
            </div>
        )
    }
}
