import React from "react";
import styles from "./index.module.less";

class CardSample extends React.Component {
    render() {
        const {imgSrc, loyaltyNumber, companyName, cardId, color, ...res} = this.props;
        return (
            <div
                // style={{backgroundImage: `url()`}}
                className={styles.cardWrap}
                {...res}
            >
                {
                    imgSrc
                    ?
                    <img src={imgSrc} alt="card img"/>
                    :
                    <div className={styles.fakeImg} style={{backgroundColor: color}}>
                        <p>{companyName.toString().substring(0, 1).toUpperCase()}</p>
                    </div>
                }

                <p className={styles.cardInfo}>{companyName} {loyaltyNumber}</p>
            </div>
        )
    }


}

export default CardSample;
