import React, {/*lazy, */Suspense} from 'react';
import 'antd/dist/antd.css';
// import history from "./MyMenu/history";
import {createBrowserHistory} from 'history';
import Main from "./pages/Main";
import {Route, Switch} from "react-router";
import {BrowserRouter as Router} from "react-router-dom";
import config from "./config";
import PageLoading from "./components/PageLoading";

import MyCards from "./pages/MyCards";
import CardDetails from "./pages/MyCards/CardDetails";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import ResetPassword from "./pages/ForgetPassword";


// const MyCards = lazy(() => import("./pages/MyCards"));
// const CardDetails = lazy(() => import("./pages/MyCards/CardDetails"));
// const Profile = lazy(() => import("./pages/Profile"));
// const Login = lazy(() => import("./pages/Login"));
// const ResetPassword = lazy(() => import("./pages/ForgetPassword"));

class App extends React.Component {

    render() {
        const history = createBrowserHistory();
        return (
            <Router history={history}>
                    <Switch>
                        <Route exact path={config.ROUTES.LOGIN} component={Login} />
                        <Route exact path={config.ROUTES.RESET_PASSWORD} component={ResetPassword}/>
                        <Main>
                            <Suspense fallback={<PageLoading spinning={true}/>}>
                                <Route exact path={config.ROUTES.MY_CARDS} component={MyCards}/>
                                <Route path={config.ROUTES.CARD_DETAILS} component={CardDetails}/>
                                <Route exact path={config.ROUTES.CARD} component={CardDetails}/>
                                <Route path={config.ROUTES.PROFILE} component={Profile}/>
                            </Suspense>
                        </Main>
                    </Switch>
            </Router>
        );
    }
}

export default App;
