import _ from 'lodash';

class ModelManager {
    constructor(entity_instance) {
        this.models = [];
        this.entity_instance = entity_instance;
    }

    addModel(model) {
        this.models.push(model);
        this.entity_instance.models[model.name] = model;

        return model;
    }

    removeModel(modelToRemove) {
        this.models = this.models.filter(model => model.name !== modelToRemove.name);

        delete this.entity_instance.models[modelToRemove.name];
    }

    getModel(against, options) {
        options = _.defaults(options || {}, {
            attribute: 'name'
        });

        return this.models.find(model => model[options.attribute] === against);
    }

    get all() {
        return this.models;
    }

}

export default ModelManager;
