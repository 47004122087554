import React from "react";
import styles from "./index.module.less";
import {Col, Descriptions, Form, Modal, Row, Select, Spin, Switch} from "antd/lib/index";
import {getRounding2} from "../../../../lib";
import BizexBtn from "../../../../components/BizexBtn";
import config from "../../../../config";
import {message} from "../../../../lib";
import {editLoyaltyCard, getCurrentLoyaltyUser} from "../../../../service";
import * as numeral from "numeral";
import {inject, observer} from "mobx-react";
import moment from "moment";
import {Checkbox, Collapse, Input, Tooltip} from "antd";
import {withRouter} from "react-router";
import EditOutlined from "@ant-design/icons/es/icons/EditOutlined";

@inject("stores")
@observer
class CardInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingSubmit: false,
            showEditInfoModal: false,
            daysList: [],

            showEdit: false,
        };
        this.cardId = parseInt(this.props.match.params.id);
        this.formRef = React.createRef();
        this.areaCodeMap = [
            {
                area: 'Auckland',
                code: '09'
            },                {
                area: 'Christchurch',
                code: '03'
            },                {
                area: 'Dunedin',
                code: '03'
            },                {
                area: 'Hamilton',
                code: '07'
            },                {
                area: 'Hastings',
                code: '06'
            },                {
                area: 'Invercargill',
                code: '03'
            },                {
                area: 'Napier',
                code: '06'
            },                {
                area: 'Nelson',
                code: '03'
            },                {
                area: 'New Plymouth',
                code: '06'
            },                {
                area: 'New Zealand External Territories',
                code: '024'
            },                {
                area: 'North Island -  Central and East',
                code: '07'
            },                {
                area: 'North Island - North',
                code: '09'
            },                {
                area: 'North Island - West',
                code: '06'
            },                {
                area: 'Palmerston North',
                code: '06'
            },                {
                area: 'Pokeno',
                code: '09'
            },                {
                area: 'Rotorua',
                code: '07'
            },                {
                area: 'South Island',
                code: '03'
            },                {
                area: 'Tauranga',
                code: '07'
            },                {
                area: 'Timaru',
                code: '03'
            },                {
                area: 'Wanganui',
                code: '06'
            },                {
                area: 'Wellington Region',
                code: '04'
            },                {
                area: 'Whangarei',
                code: '09'
            }
        ];
    }

    componentDidMount() {
        this.setState({loading: true}, async () => {
            try {
                const res = await getCurrentLoyaltyUser();
                if (res.error) throw res.error;
                if (res.data && res.data.userinfo) {
                    this.props.stores.AuthStore.setUser(res.data.userinfo);
                }
                if (res.data.userinfo.card_lines) {
                    for (const card of res.data.userinfo.card_lines) {
                        if (card.id === this.cardId) {
                            this.props.stores.CardStore.setCard(card);
                            break;
                        }
                    }
                }
            } catch (e) {
                message.error(e.message)
            } finally {
                this.setState({loading: false})
            }
        });
    }

    handleSubmitForm = () => {
        this.formRef.current.submit();
    };

    handleSubmitEditInfo = values => {
        this.setState({loadingSubmit: true}, async () => {
            try {
                // console.log(this.cardId);
                const data = {
                    id: this.cardId,
                    address_line_1: values.address_line_1,
                    address_line_2: values.address_line_2,
                    address_line_3: values.address_line_3,
                    phone_1: values.phone_1,
                    phone_2: values.landLine ? `${values.areaCode}-${values.landLine}` : "",
                    birthday: values.year + "-" + values.month + (values.day ? "-" + values.day : ""),
                    to_email: values.to_email,
                    apply_to_all: values.apply_to_all,
                };
                let res = await editLoyaltyCard(data);
                if (res.error) throw res.error;
                res = await getCurrentLoyaltyUser();
                if (res.error) throw res.error;
                if (res.data && res.data.userinfo) {
                    this.props.stores.AuthStore.setUser(res.data.userinfo);
                }
                if (res.data.userinfo.card_lines) {
                    for (const card of res.data.userinfo.card_lines) {
                        if (card.id === this.cardId) {
                            this.props.stores.CardStore.setCard(card);
                            break;
                        }
                    }
                }
                this.handleEditInfoModalCancel()
            } catch (e) {
                let msg = e.message;
                if (msg.indexOf("phone 1 cannot be empty") > -1) {
                    msg = "mobile cannot be empty";
                }
                message.error(msg);
                this.setState({loadingSubmit: false});
            }
        })
    };

    handleShowEditInfoModal = (e) => {
        e.stopPropagation();
        this.getDaySelectOptions();
        this.setState({
            showEditInfoModal: true,
        })
    };

    handleEditInfoModalCancel = () => {
        this.setState({
            showEditInfoModal: false,
            loadingSubmit: false,
        }, () => {
            this.formRef.current.resetFields();
        })
    };

    getBirthdayStr = birthday => {
        if (birthday) {
            const birthdayObj = moment(birthday, "YYYY-MM-DD");
            if (birthday.length > 7) {
                return birthdayObj.format("DD MMMM YYYY");
            } else {
                return birthdayObj.format("MMMM YYYY");
            }
        } else {
            return "";
        }
    };

    getMonthSelectOptions = () => {
        let options = [];
        for (let i = 1; i <= 12; i++) {
            const monthStr = moment(i, "M").format("MMM.");
            const monthKey = moment(i, "M").format("MM");
            const option = (
                <Select.Option key={monthKey} value={monthKey}>{monthStr}</Select.Option>
            );
            options.push(option)
        }
        return options;
    };

    getDaySelectOptions = () => {
        const year = this.formRef.current.getFieldValue("year");
        const month = this.formRef.current.getFieldValue("month");
        let daysList = [];

        if (!year || !month) {
            let {currentCard} = this.props.stores.CardStore;
            if (currentCard && currentCard.birthday) {
                const birthday = moment(currentCard.birthday, "YYYY-MM-DD");
                const dayNumber = birthday.daysInMonth();
                for (let i = 1; i <= dayNumber; i++) {
                    daysList.push(numeral(i).format("00"));
                }
                if (this.formRef.current.getFieldValue("day") > dayNumber) {
                    this.formRef.current.setFieldsValue({day: dayNumber});
                }
            }
            this.setState({daysList});
            this.formRef.current.setFieldsValue({day: undefined})
        } else {
            const momentObj = moment(`${year} ${month}`, "YYYY MM");
            const dayNumber = momentObj.daysInMonth();
            // console.log(dayNumber);
            for (let i = 1; i <= dayNumber; i++) {
                daysList.push(numeral(i).format("00"));
            }
            if (this.formRef.current.getFieldValue("day") > dayNumber) {
                this.formRef.current.setFieldsValue({day: dayNumber});
            }
            this.setState({daysList});
        }
    };

    getYearSelectOptions = (years) => {
        const currentYear = numeral(moment().format("YYYY")).value();
        let options = [];
        for (let i = 0; i < years; i++) {
            const year = currentYear - i;
            const option = (
                <Select.Option key={year} value={year}>{year}</Select.Option>
            );
            options.push(option)
        }
        return options;
    };

    getAreaCode = value => {
        if (value) {
            for (const map of this.areaCodeMap) {
                if (value.toLowerCase() === map.area.toLowerCase()) {
                    return map.code;
                }
            }
        }
        return "";
    };

    handleCollapseChange = e => {
        if (e.length === 1) {
            this.setState({showEdit: true})
        } else {
            this.setState({showEdit: false})
        }
    };

    render() {
        let {currentCard} = this.props.stores.CardStore;
        if (!currentCard) {
            currentCard = {};
        }
        return (
            <div className={styles.wrap}>
                <div>
                    <Spin className={styles.spin} spinning={this.state.loading}/>
                </div>
                <div>
                    <h1 className={styles.title}>Card Info</h1>
                    {/*<BizexBtn*/}
                    {/*    key={1}*/}
                    {/*    className={styles.btn}*/}
                    {/*    onClick={this.handleShowEditInfoModal}*/}
                    {/*>*/}
                    {/*    Edit Card Info*/}
                    {/*</BizexBtn>*/}
                </div>

                <Row>
                    <Col xs={24} sm={24} md={7}>
                        <p className={styles.infoTitle}>Dollars redeemable:</p>
                        <p className={styles.infoValue}>$ {getRounding2(currentCard.balance)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={7}>
                        <p className={styles.infoTitle}>Sale value:</p>
                        <p className={styles.infoValue}>$ {getRounding2(currentCard.point)}</p>
                    </Col>
                </Row>

                <Row>
                    <Collapse className={styles.collapse} onChange={this.handleCollapseChange}>
                        <Collapse.Panel
                            header={
                                <span>
                                    More information {this.state.showEdit && <Tooltip title={"Click to edit"}><EditOutlined className={styles.edit} onClick={this.handleShowEditInfoModal}/></Tooltip>}
                                </span>
                            }
                            key={1}
                        >
                            {/*<Col span={12}>*/}
                            <Descriptions
                                title={null}
                                layout="vertical"
                                column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
                                className={styles.descriptions}
                            >
                                <Descriptions.Item label={<span className={styles.label}>Company Name</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.loyalty_company_name}
                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className={styles.label}>Loyalty Number</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.loyalty_number}
                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className={styles.label}>Birthday</span>}>
                                <span className={styles.descriptionsItem}>
                                    {this.getBirthdayStr(currentCard.birthday)}
                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className={styles.label}>Receive Newsletters</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.to_email ? "Yes" : "No"}
                                </span>
                                </Descriptions.Item>
                                {/*    </Descriptions>*/}
                                {/*/!*</Col>*!/*/}
                                {/*/!*<Col span={12}>*!/*/}
                                {/*    <Descriptions title={null} layout="vertical" column={1} className={styles.descriptions}>*/}
                                <Descriptions.Item label={<span className={styles.label}>Active</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.active ? "Yes" : "No"}
                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className={styles.label}>Mobile</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.phone_1 ? currentCard.phone_1 : '-'}
                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className={styles.label}>Land Line</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.phone_2 ? currentCard.phone_2 : '-'}
                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className={styles.label}>Address</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentCard.address_line_1}
                                    {(currentCard.address_line_1 && currentCard.address_line_2) || (currentCard.address_line_1 && currentCard.address_line_3) ? <br/> : ""}
                                    {currentCard.address_line_2}
                                    {(currentCard.address_line_2 && currentCard.address_line_3) ? <br/> : ""}
                                    {currentCard.address_line_3}
                                    {
                                        !currentCard.address_line_1 &&
                                        !currentCard.address_line_2 &&
                                        !currentCard.address_line_3 ? "-" : ""
                                    }
                                </span>
                                </Descriptions.Item>
                            </Descriptions>
                            {/*</Col>*/}
                        </Collapse.Panel>
                    </Collapse>
                </Row>

                <Modal
                    visible={this.state.showEditInfoModal}
                    title={"Edit Card Info"}
                    forceRender
                    onCancel={this.handleEditInfoModalCancel}
                    footer={[
                        <BizexBtn
                            key={"modalBtn0"}
                            className={styles.formBtn}
                            btnType={config.BIZEX_BTN_TYPES.BLUE_GHOST}
                            onClick={this.handleEditInfoModalCancel}
                        >
                            Cancel
                        </BizexBtn>,
                        <BizexBtn
                            key={"modalBtn1"}
                            className={styles.formBtn}
                            loading={this.state.loadingSubmit}
                            onClick={this.handleSubmitForm}
                        >
                            Submit
                        </BizexBtn>
                    ]}
                >
                    <Form
                        ref={this.formRef}
                        onFinish={this.handleSubmitEditInfo}
                        labelCol={{span: 9}}
                        wrapperCol={{span: 15}}
                        initialValues={{
                            year: currentCard.birthday ? moment(currentCard.birthday, "YYYY-MM-DD HH:mm:ss").format("YYYY") : undefined,
                            month: currentCard.birthday ? moment(currentCard.birthday, "YYYY-MM-DD HH:mm:ss").format("MM") : undefined,
                            day: currentCard.birthday && currentCard.birthday.length > 7 ? moment(currentCard.birthday, "YYYY-MM-DD HH:mm:ss").format("DD") : undefined,
                            address_line_1: currentCard.address_line_1,
                            address_line_2: currentCard.address_line_2,
                            address_line_3: currentCard.address_line_3,
                            phone_1: currentCard.phone_1,
                            areaCode: currentCard.phone_2 && currentCard.phone_2.indexOf("-") > -1 ? currentCard.phone_2.split("-")[0] : this.getAreaCode(currentCard.address_line_3),
                            landLine: currentCard.phone_2 && currentCard.phone_2.indexOf("-") > -1 ? currentCard.phone_2.split("-")[1] : currentCard.phone_2,
                            to_email: currentCard.to_email,
                        }}
                    >
                        <Form.Item
                            label={"Birthday"}
                            required={true}
                        >
                            <Input.Group compact>
                                <Form.Item noStyle name="year" rules={[{required: true, message: "Year and month are required"}]}>
                                    <Select showSearch placeholder={"Year"} style={{width: "34%"}} onChange={this.getDaySelectOptions}>
                                        {
                                            this.getYearSelectOptions(100)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item noStyle name="month" rules={[{required: true, message: "Year and month are required"}]}>
                                    <Select
                                        showSearch
                                        placeholder={"Month"}
                                        style={{width: "33%"}}
                                        onChange={this.getDaySelectOptions}
                                        filterOption={(input, option) => {
                                            return option.value.toString().indexOf(input.toLowerCase()) >= 0 || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                    >
                                        {
                                            this.getMonthSelectOptions()
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item noStyle name="day">
                                    <Select showSearch placeholder={"Day"} style={{width: "33%"}} allowClear>
                                        {
                                            this.state.daysList.map(item => {
                                                return <Select.Option key={item} value={item}>{item}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item
                            name={"address_line_1"}
                            label={"Street"}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"address_line_2"}
                            label={"Suburb"}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name={"address_line_3"}
                            label={"City"}
                        >
                            <Select
                                // allowClear
                                showSearch
                                onSelect={value => this.formRef.current.setFieldsValue({areaCode: this.getAreaCode(value)})}
                                placeholder="City"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                            >
                                {
                                    this.areaCodeMap.map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.area}>
                                                {item.area}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={"phone_1"}
                            label={"Mobile"}
                            rules={[{required: true, message: "Mobile is required"}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={"Land Line"}
                        >
                            <Input.Group compact>
                                <Form.Item noStyle name="areaCode">
                                    <Input placeholder={"Prefix"} style={{width: "20%"}} disabled/>
                                </Form.Item>
                                <Form.Item noStyle name="landLine">
                                    <Input placeholder={"Land line "} style={{width: "80%"}}/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <Form.Item
                            name={"to_email"}
                            label={"Receive Newsletters"}
                            valuePropName="checked"
                        >
                            <Switch
                                defaultChecked={currentCard.to_email}
                                checkedChildren={"Yes"}
                                unCheckedChildren={"No"}
                            />
                        </Form.Item>

                        <Form.Item
                            name={"apply_to_all"}
                            label={"Apply to all cards"}
                            valuePropName="checked"
                        >
                            <Checkbox/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default withRouter(CardInfo);
