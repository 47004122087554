import React from "react";
import styles from "./index.module.less";
import {withRouter} from "react-router";
import {DatePicker, Form, InputNumber, Modal, Select, Table} from "antd";
import config from "../../../../config";
import {getCurrentLoyaltyUser, listLoyaltyTransfer, transferLoyaltyCredit} from "../../../../service";
import {inject, observer} from "mobx-react";
import {apiTimeStrToMoment, getRounding2, message} from "../../../../lib";
import * as numeral from "numeral";
import PageLoading from "../../../../components/PageLoading";
import BizexBtn from "../../../../components/BizexBtn";


@inject('stores')
@observer
class TransferRecords extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingSubmit: false,
            cardFrom: undefined,
            cardTo: undefined,
            // inDateFrom: null,
            // inDateTo: null,
            // outDateFrom: null,
            // outDateTo: null,
            dateFrom: undefined,
            dateTo: undefined,
            page: 1,
            count: 0,
            // transferInRecords: [],
            // transferOutRecords: [],
            records: [],
            cards: [],
            showTransferModal: false,
        };
        this.pageSize = config.TRANSACTIONS_PAGE_SIZE;
        this.dataFormat = "DD/MM/YYYY";
        this.columns = [
            {
                title: "#",
                dataIndex: "id",
                key: "id",
                align: "center",
            },
            {
                title: "Card From",
                dataIndex: "loyalty_card_id_from",
                key: "loyalty_card_id_from",
                align: "center",
                render: value => {
                    for (const card of this.state.cards) {
                        if (card.id === value) {
                            return card.loyalty_number;
                        }
                    }
                }
            },
            {
                title: "Card To",
                dataIndex: "loyalty_card_id_to",
                key: "loyalty_card_id_to",
                align: "center",
                render: value => {
                    for (const card of this.state.cards) {
                        if (card.id === value) {
                            return card.loyalty_number;
                        }
                    }
                }
            },
            {
                title: "Time",
                dataIndex: "transfer_date",
                key: "transfer_date",
                align: "center",
                render: value => apiTimeStrToMoment(value).format("DD/MM/YYYY HH:ss:mm"),
            },
            {
                title: "Amount",
                dataIndex: "transfer_amount",
                key: "transfer_amount",
                align: "right",
                render: value => numeral(getRounding2(value)).format('$0,0.00'),
            }
        ];
        // this.transferInColumns = [
        //     {
        //         title: "#",
        //         dataIndex: "id",
        //         key: "id",
        //         align: "center",
        //     },
        //     // {
        //     //     title: "Company",
        //     //     dataIndex: "loyalty_company_id",
        //     //     key: "loyalty_company_id",
        //     //     align: "center",
        //     //     render: (value) => {
        //     //         for (const card of this.state.cards) {
        //     //             if (card.loyalty_company_id === value) {
        //     //                 return card.loyalty_company_name;
        //     //             }
        //     //         }
        //     //     }
        //     // },
        //     {
        //         title: "Card From",
        //         dataIndex: "loyalty_card_id_from",
        //         key: "loyalty_card_id_from",
        //         align: "center",
        //         render: value => {
        //             for (const card of this.state.cards) {
        //                 if (card.id === value) {
        //                     return card.loyalty_number;
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         title: "Time",
        //         dataIndex: "transfer_date",
        //         key: "transfer_date",
        //         align: "center",
        //         render: value => apiTimeStrToMoment(value).format("DD/MM/YYYY HH:ss:mm"),
        //     },
        //     {
        //         title: "Amount",
        //         dataIndex: "transfer_amount",
        //         key: "transfer_amount",
        //         align: "right",
        //         render: value => numeral(getRounding2(value)).format('$0,0.00'),
        //     }
        // ];
        // this.transferOutColumns = [
        //     {
        //         title: "#",
        //         dataIndex: "id",
        //         key: "id",
        //         align: "center",
        //     },
        //     // {
        //     //     title: "Company",
        //     //     dataIndex: "loyalty_company_id",
        //     //     key: "loyalty_company_id",
        //     //     align: "center",
        //     //     render: (value) => {
        //     //         for (const card of this.state.cards) {
        //     //             if (card.loyalty_company_id === value) {
        //     //                 return card.loyalty_company_name;
        //     //             }
        //     //         }
        //     //     }
        //     // },
        //     // {
        //     //     title: "Card From",
        //     //     dataIndex: "loyalty_card_id_from",
        //     //     key: "loyalty_card_id_from",
        //     //     align: "center",
        //     //     render: value => {
        //     //         for (const card of this.state.cards) {
        //     //             if (card.id === value) {
        //     //                 return card.loyalty_number;
        //     //             }
        //     //         }
        //     //     }
        //     // },
        //     {
        //         title: "Card To",
        //         dataIndex: "loyalty_card_id_to",
        //         key: "loyalty_card_id_to",
        //         align: "center",
        //         render: value => {
        //             for (const card of this.state.cards) {
        //                 if (card.id === value) {
        //                     return card.loyalty_number;
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         title: "Time",
        //         dataIndex: "transfer_date",
        //         key: "transfer_date",
        //         align: "center",
        //         render: value => apiTimeStrToMoment(value).format("DD/MM/YYYY HH:ss:mm"),
        //     },
        //     {
        //         title: "Amount",
        //         dataIndex: "transfer_amount",
        //         key: "transfer_amount",
        //         align: "right",
        //         render: value => numeral(getRounding2(value)).format('$0,0.00'),
        //     }
        // ];
        // this.transferOptions = {
        //     in: 0,
        //     out: 1,
        // };
        this.timeOptions = {
            timeFrom: 0,
            timeTo: 1,
        };
        this.currentCard = null;
        this.formRef = React.createRef();
    }


    componentDidMount() {
        const {currentUser} = this.props.stores.AuthStore;
        const cards = currentUser.card_lines;
        const cardId = this.props.stores.CardStore.currentCard.id;
        for (const card of cards) {
            if (card.id === cardId) {
                this.currentCard = card;
                break;
            }
        }
        this.setState({cards});
        this.loadData();

        // this.setState({loading: true}, async () => {
        //     try {
        //         const {currentUser} = this.props.stores.AuthStore;
        //         const cards = currentUser.card_lines;
        //         const cardId = this.props.stores.CardStore.currentCard.id;
        //         for (const card of cards) {
        //             if (card.id === cardId) {
        //                 this.currentCard = card;
        //                 break;
        //             }
        //         }
        //         this.setState({cards});
        //
        //         this.loadTransferRecords(this.transferOptions.in);
        //         this.loadTransferRecords(this.transferOptions.out);
        //     } catch (e) {
        //         message.error(e.message);
        //     } finally {
        //         this.setState({loading: false})
        //     }
        // });
    }


    loadData = () => {
        this.setState({loading: true}, async () => {
            try {
              const companyId = this.currentCard.loyalty_company_id;
                const {dateFrom, dateTo, cardFrom, cardTo, page} = this.state;
                let dateFromStr = "", dateToStr = "";

                if (dateFrom) {
                    dateFromStr = dateFrom.format("YYYY-MM-DD");
                }
                if (dateTo) {
                    dateToStr = dateTo.format("YYYY-MM-DD");
                }
                const res = await listLoyaltyTransfer(companyId, cardFrom, cardTo, dateFromStr, dateToStr, page, this.pageSize);
                if (res.error) throw res.error;
                const {data} = res;
                let records = [];
                if (data && data.list) {
                    records = data.list;
                }
                const {count} = data;
                this.setState({records, count});
            } catch (e) {
                message.error(e.message)
            } finally {
                this.setState({loading: false})
            }
        })
    };

    // loadTransferRecords = (transferOption) => {
    //     this.setState({loading: true}, async () => {
    //         try {
    //             const cardId = this.currentCard.id;
    //             const companyId = this.currentCard.loyalty_company_id;
    //             const {inDateFrom, inDateTo, outDateFrom, outDateTo, page} = this.state;
    //             let dateFromStr = "", dateToStr = "";
    //
    //             if (transferOption === this.transferOptions.in) {
    //                 if (inDateFrom) {
    //                     dateFromStr = inDateFrom.format("YYYY-MM-DD");
    //                 }
    //                 if (inDateTo) {
    //                     dateToStr = inDateTo.format("YYYY-MM-DD");
    //                 }
    //                 const res = await listLoyaltyTransfer(companyId, "", cardId, dateFromStr, dateToStr);
    //                 if (res.error) throw res.error;
    //                 const {data} = res;
    //                 let transferInRecords = [];
    //                 if (data && data.list) {
    //                     transferInRecords = data.list;
    //                 }
    //                 this.setState({transferInRecords});
    //             } else {
    //                 if (outDateFrom) {
    //                     dateFromStr = outDateFrom.format("YYYY-MM-DD");
    //                 }
    //                 if (outDateTo) {
    //                     dateToStr = outDateTo.format("YYYY-MM-DD");
    //                 }
    //                 const res = await listLoyaltyTransfer(companyId, cardId, "", dateFromStr, dateToStr);
    //                 if (res.error) throw res.error;
    //                 const {data} = res;
    //                 let transferOutRecords = [];
    //                 if (data && data.list) {
    //                     transferOutRecords = data.list;
    //                 }
    //                 this.setState({transferOutRecords});
    //             }
    //
    //         } catch (e) {
    //             message.error(e.message)
    //         } finally {
    //             this.setState({loading: false})
    //         }
    //     })
    // };

    changeCardFrom = (cardFrom) => {
        // this.setState({cardFrom}, () => this.loadTransferRecords(this.transferOptions.in));
        this.setState({cardFrom}, () => this.loadData());
    };

    changeCardTo = cardTo => {
        // this.setState({cardTo}, () => this.loadTransferRecords(this.transferOptions.out));
        this.setState({cardTo}, () => this.loadData());
    };

    changeDateRange = (value, timeOption) => {
        if (timeOption === this.timeOptions.timeFrom) {
            this.setState({dateFrom: value}, () => {
                this.loadData();
            });
        } else if (timeOption === this.timeOptions.timeTo) {
            this.setState({dateTo: value}, () => {
                this.loadData();
            });
        }
    };

    // changeDateRange = (value, transferOption, timeOption) => {
    //     if (transferOption === this.transferOptions.in) {
    //         if (timeOption === this.timeOptions.timeFrom) {
    //             this.setState({inDateFrom: value}, () => {
    //                 this.loadTransferRecords(this.transferOptions.in);
    //             });
    //         } else if (timeOption === this.timeOptions.timeTo) {
    //             this.setState({inDateTo: value}, () => {
    //                 this.loadTransferRecords(this.transferOptions.in);
    //             });
    //         }
    //
    //     } else if (transferOption === this.transferOptions.out) {
    //         if (timeOption === this.timeOptions.timeFrom) {
    //             this.setState({outDateFrom: value}, () => {
    //                 this.loadTransferRecords(this.transferOptions.out);
    //             });
    //         } else if (timeOption === this.timeOptions.timeTo) {
    //             this.setState({outDateTo: value}, () => {
    //                 this.loadTransferRecords(this.transferOptions.out);
    //             });
    //         }
    //     }
    // };

    showTransferModal = () => {
        this.setState({showTransferModal: true});
    };

    handleTransferModalCancel = () => {
        this.setState({
            showTransferModal: false,
            loadingSubmit: false,
        });
        this.formRef.current.resetFields();
    };

    submitForm = () => {
        this.formRef.current.submit();
    };

    handleFormSubmit = values => {
        this.setState({loadingSubmit: true}, async () => {
            try {
                const cardFrom = this.currentCard.id;
                const cardTo = values.transferTo;
                const amount = values.amount;
                let res = await transferLoyaltyCredit(cardFrom, cardTo, amount);
                if (res.error) throw res.error;
                res = await getCurrentLoyaltyUser();
                if (res.error) throw res.error;
                const {data} = res;
                if (data && data.userinfo && data.userinfo.card_lines) {
                    this.props.stores.AuthStore.setUser(data.userinfo);
                    for (const card of data.userinfo.card_lines) {
                        if (card.id === cardFrom) {
                            this.props.stores.CardStore.setCard(card);
                            break;
                        }
                    }
                }
                this.loadData();
                // this.loadTransferRecords(this.transferOptions.out);
                this.handleTransferModalCancel();
            } catch (e) {
                message.error(e.message);
                this.setState({loadingSubmit: false});
            }
        })
    };

    changeTable = (pagination, /* filters, sorter */) => {
        this.setState({loading: true, page: pagination.current}, async () => {
            await this.loadData();
            this.setState({loading: false})
        })
    };

    render() {
        return (
            <div className={styles.wrap}>
                <PageLoading spinning={this.state.loading}/>
                <h1 className={styles.title}>
                    {/*<img src={require("../../../img/blue_logo.png")} alt="logo" className={styles.titleLogo}/>*/}
                    Transfer Records
                </h1>
                <BizexBtn className={styles.transferBtn} onClick={this.showTransferModal}>Transfer Dollars</BizexBtn>
                {/*<div>*/}
                {/*    <h1 className={styles.tableName}>Transfer In</h1>*/}
                {/*    <div className={styles.filterWrap}>*/}
                {/*        <span className={styles.filter}>*/}
                {/*            <span className={styles.filterTitle}>Card From:</span>*/}
                {/*            <Select*/}
                {/*                className={styles.filterInput}*/}
                {/*                placeholder={"Card From"}*/}
                {/*                onChange={value => this.changeCardFrom(value)}*/}
                {/*                value={this.state.cardFrom}*/}
                {/*                allowClear*/}
                {/*            >*/}
                {/*                {*/}
                {/*                    this.state.cards.filter(card => {*/}
                {/*                        const companyId = this.currentCard.loyalty_company_id;*/}
                {/*                        const cardId = this.currentCard.id;*/}
                {/*                        return card.loyalty_company_id === companyId && card.id !== cardId;*/}
                {/*                    }).map(card => {*/}
                {/*                        return (*/}
                {/*                            <Select.Option key={card.id} value={card.id}>*/}
                {/*                                {card.loyalty_company_name} {card.loyalty_number}*/}
                {/*                            </Select.Option>*/}
                {/*                        );*/}
                {/*                    })*/}
                {/*                }*/}
                {/*            </Select>*/}
                {/*        </span>*/}

                {/*        <span className={styles.filter}>*/}
                {/*            <span className={styles.filterTitle}>Date filter:</span>*/}
                {/*            <DatePicker*/}
                {/*                format={this.dataFormat}*/}
                {/*                placeholder={"Date From"}*/}
                {/*                onChange={(value) => this.changeDateRange(value, this.transferOptions.in, this.timeOptions.timeFrom)}*/}
                {/*                value={this.state.inDateFrom}*/}
                {/*                className={styles.filterInput}*/}
                {/*                disabledDate={value => {*/}
                {/*                    return value && this.state.inDateTo && value >= this.state.inDateTo*/}
                {/*                }}*/}
                {/*            />*/}
                {/*             -*/}
                {/*            <DatePicker*/}
                {/*                format={this.dataFormat}*/}
                {/*                placeholder={"Date to"}*/}
                {/*                onChange={(value) => this.changeDateRange(value, this.transferOptions.in, this.timeOptions.timeTo)}*/}
                {/*                value={this.state.inDateTo}*/}
                {/*                className={styles.filterInput}*/}
                {/*                disabledDate={value => {*/}
                {/*                    return value && value <= this.state.inDateFrom*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <Table*/}
                {/*        rowKey={(r, k) => k}*/}
                {/*        columns={this.transferInColumns}*/}
                {/*        dataSource={this.state.transferInRecords}*/}
                {/*        pagination={{*/}
                {/*            pageSize: this.pageSize,*/}
                {/*            total: this.state.count,*/}
                {/*            page: this.state.page,*/}
                {/*        }}*/}
                {/*        onChange={this.onChangePage}*/}
                {/*        className={styles.transferInTable}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*    <h1 className={styles.tableName}>Transfer Out</h1>*/}
                {/*    <div className={styles.filterWrap}>*/}
                {/*        <span className={styles.filter}>*/}
                {/*            <span className={styles.filterTitle}>Card To:</span>*/}
                {/*            <Select*/}
                {/*                className={styles.filterInput}*/}
                {/*                placeholder={"Card To"}*/}
                {/*                onChange={this.changeCardTo}*/}
                {/*                value={this.state.cardTo}*/}
                {/*                allowClear*/}
                {/*            >*/}
                {/*                {*/}
                {/*                    this.state.cards.filter(card => {*/}
                {/*                        const companyId = this.currentCard.loyalty_company_id;*/}
                {/*                        const cardId = this.currentCard.id;*/}
                {/*                        return card.loyalty_company_id === companyId && card.id !== cardId;*/}
                {/*                    }).map(card => {*/}
                {/*                        return (*/}
                {/*                            <Select.Option key={card.id} value={card.id}>*/}
                {/*                                {card.loyalty_company_name} {card.loyalty_number}*/}
                {/*                            </Select.Option>*/}
                {/*                        );*/}
                {/*                    })*/}
                {/*                }*/}
                {/*            </Select>*/}
                {/*        </span>*/}
                {/*        <span className={styles.filter}>*/}
                {/*            <span className={styles.filterTitle}>Date filter:</span>*/}
                {/*            <DatePicker*/}
                {/*                format={this.dataFormat}*/}
                {/*                placeholder={"Date From"}*/}
                {/*                onChange={(value) => this.changeDateRange(value, this.transferOptions.out, this.timeOptions.timeFrom)}*/}
                {/*                value={this.state.outDateFrom}*/}
                {/*                className={styles.filterInput}*/}
                {/*                disabledDate={value => {*/}
                {/*                    return value && this.state.outDateTo && value >= this.state.outDateTo*/}
                {/*                }}*/}
                {/*            />*/}
                {/*             -*/}
                {/*            <DatePicker*/}
                {/*                format={this.dataFormat}*/}
                {/*                placeholder={"Date to"}*/}
                {/*                onChange={(value) => this.changeDateRange(value, this.transferOptions.out, this.timeOptions.timeTo)}*/}
                {/*                value={this.state.outDateTo}*/}
                {/*                className={styles.filterInput}*/}
                {/*                disabledDate={value => {*/}
                {/*                    return value && value <= this.state.outDateFrom*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <Table*/}
                {/*        rowKey={(r, k) => k}*/}
                {/*        columns={this.transferOutColumns}*/}
                {/*        dataSource={this.state.transferOutRecords}*/}
                {/*        pagination={{*/}
                {/*            pageSize: this.pageSize,*/}
                {/*            total: this.state.count,*/}
                {/*            page: this.state.page,*/}
                {/*        }}*/}
                {/*        onChange={this.onChangePage}*/}
                {/*        className={styles.transferOutTable}*/}
                {/*    />*/}
                {/*</div>*/}

                <div>
                    <div className={styles.filterWrap}>
                        <span className={styles.filter}>
                            <span className={styles.filterTitle}>Card From:</span>
                            <Select
                                className={styles.filterCard}
                                placeholder={"Card From"}
                                onChange={value => this.changeCardFrom(value)}
                                value={this.state.cardFrom}
                                allowClear
                            >
                                {
                                    this.state.cards.filter(card => {
                                        const companyId = this.currentCard.loyalty_company_id;
                                        return card.loyalty_company_id === companyId;
                                    }).map(card => {
                                        return (
                                            <Select.Option key={card.id} value={card.id}>
                                                {card.loyalty_company_name} {card.loyalty_number}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </span>

                        <span className={styles.filter}>
                            <span className={styles.filterTitle}>Card To:</span>
                            <Select
                                className={styles.filterCard}
                                placeholder={"Card To"}
                                onChange={this.changeCardTo}
                                value={this.state.cardTo}
                                allowClear
                            >
                                {
                                    this.state.cards.filter(card => {
                                        const companyId = this.currentCard.loyalty_company_id;
                                        return card.loyalty_company_id === companyId;
                                    }).map(card => {
                                        return (
                                            <Select.Option key={card.id} value={card.id}>
                                                {card.loyalty_company_name} {card.loyalty_number}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </span>

                        <span className={styles.filter}>
                            <span className={styles.filterTitle}>Date:</span>
                            <DatePicker
                                format={this.dataFormat}
                                placeholder={"Date From"}
                                onChange={(value) => this.changeDateRange(value, this.timeOptions.timeFrom)}
                                value={this.state.dateFrom}
                                className={styles.filterDate}
                                disabledDate={value => {
                                    return value && this.state.dateTo && value >= this.state.dateTo
                                }}
                            />
                             -
                            <DatePicker
                                format={this.dataFormat}
                                placeholder={"Date to"}
                                onChange={(value) => this.changeDateRange(value, this.timeOptions.timeTo)}
                                value={this.state.dateTo}
                                className={styles.filterDate}
                                disabledDate={value => {
                                    return value && value <= this.state.dateFrom
                                }}
                            />
                        </span>
                    </div>
                    <Table
                        rowKey={"id"}
                        columns={this.columns}
                        dataSource={this.state.records}
                        tableLayout={"fixed"}
                        onChange={this.changeTable}
                        pagination={{
                            pageSize: this.pageSize,
                            total: this.state.count,
                            page: this.state.page,
                        }}
                    />
                </div>

                <Modal
                    visible={this.state.showTransferModal}
                    forceRender
                    title={"Transfer Dollars"}
                    onCancel={this.handleTransferModalCancel}
                    footer={[
                        <BizexBtn
                            key={"modalBtn0"}
                            className={styles.formBtn}
                            btnType={config.BIZEX_BTN_TYPES.BLUE_GHOST}
                            onClick={this.handleTransferModalCancel}
                        >
                            Cancel
                        </BizexBtn>,
                        <BizexBtn
                            key={"modalBtn1"}
                            className={styles.formBtn}
                            loading={this.state.loadingSubmit}
                            onClick={this.submitForm}
                        >
                            Submit
                        </BizexBtn>
                    ]}
                >
                    <Form
                        ref={this.formRef}
                        onFinish={this.handleFormSubmit}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                    >
                        <Form.Item
                            label={"Transfer From"}
                        >
                            <span>{this.currentCard && `${this.currentCard.loyalty_company_name} ${this.currentCard.loyalty_number}`}</span>
                        </Form.Item>
                        <Form.Item
                            name={"transferTo"}
                            label={"Transfer To"}
                            rules={[{required: true, message: "Please select the card transfer to."}]}
                        >
                            <Select className={styles.formInput}>
                                {
                                    this.state.cards.filter(card => {
                                        const companyId = this.currentCard.loyalty_company_id;
                                        const cardId = this.currentCard.id;
                                        return card.loyalty_company_id === companyId && card.id !== cardId;
                                    }).map(card => {
                                        return (
                                            <Select.Option key={card.id} value={card.id}>
                                                {card.loyalty_company_name} {card.loyalty_number}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"amount"}
                            label={"Amount"}
                            rules={[{required: true, message: "Please enter the amount."}]}
                        >
                            <InputNumber
                                onFocus={e => e.target.select()}
                                className={styles.formInput}
                                min={0}
                                precision={2}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default withRouter(TransferRecords);
