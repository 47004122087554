import ModelManager from './modelManager';
import Model from './model';

class Index {
    constructor(options) {
        this.options = Object.assign({}, options);
        this.models = {};
        this.modelManager = new ModelManager(this);
    }

    isDefined = modelName => {
        return !!this.modelManager.models.find(model => model.name === modelName);
    };

    static define = (modelName, attributes, options = {}) => {
        options.modelManager = modelName;
        if (!this.instance) {
            this.instance = new Index();
        }
        options.entity = this.instance;
        const model = class extends Model {};
        model.init(attributes, options);
        return model;
    }
}

Index.instance = null;

export default Index;
