import React from "react";
import {withRouter} from "react-router";
import PageLoading from "../../components/PageLoading";
import styles from "./index.module.less";
import {inject, observer} from "mobx-react";
import CardSample from "./components/CardSample";
import {Empty, List} from "antd";

@inject('stores')
@observer
class MyCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            availableCards: [],
        };
    }

    componentDidMount = () => {
        const user = this.props.stores.AuthStore.currentUser;
        let cards = [];
        if (user && user.card_lines) {
            cards = user.card_lines;
        }
        const {savedCompanyList} = this.props.stores.CardStore;
        for (const card of cards) {
            const companyId = card.loyalty_company_id;
            for (const company of savedCompanyList) {
                if (company.id === companyId) {
                    if (company.logo) {
                        card.imgSrc = company.logo;
                    } else {
                        card.color = company.color;
                    }
                }
            }
        }
        this.setState({
            availableCards: cards,
        });
    };

    clickOnCard = card => {
        this.props.stores.CardStore.setCard(card);
        this.props.history.push("card/" + card.id);
    };

    render() {
        const {availableCards} = this.state;
        return (
            <div>
                <PageLoading spinning={this.state.loading}/>
                {
                    availableCards.length > 0 ?
                    <List
                        className={styles.cards}
                        grid={{gutter: 24, xxl: 3, xl:3, lg: 2, md: 1, sm: 1, xs: 1}}
                        dataSource={availableCards}
                        renderItem={card => {
                            return (
                                <List.Item key={card.id} className={styles.cardItem}>
                                    <CardSample
                                        onClick={() => this.clickOnCard(card)}
                                        imgSrc={card.imgSrc ? card.imgSrc : ''}
                                        loyaltyNumber={card.loyalty_number}
                                        companyName={card.loyalty_company_name}
                                        cardId={card.id}
                                        color={card.color}
                                    />
                                </List.Item>
                            )
                        }}
                    />
                    :
                    <Empty className={styles.empty}/>
                }
            </div>
        )
    }
}

export default withRouter(MyCards);
