import React from "react";
import {withRouter} from "react-router";
import config from "../../../config";
import CardInfo from "./CardInfo";
import Transactions from "./Transactions";
import PageTemplate from "../../../components/PageTemplate";
import {inject, observer} from "mobx-react";
import TransferRecords from "./TransferRecords";


@inject('stores')
@observer
class CardDetails extends React.Component {
    constructor(props) {
        super(props);
        this.tabs = [
            {
                index: config.SUBMENU_ITEMS.MY_CARDS.MY_CARDS,
                tabTitle: "Card Info",
                component: <CardInfo/>,
            },
            {
                index: config.SUBMENU_ITEMS.MY_CARDS.TRANSACTIONS,
                tabTitle: "Transactions",
                component: <Transactions/>,
            },
        ];
    }

    componentDidMount() {
        if (this.props.stores.CardStore.currentCard == null) {
            const cardId = this.props.match.params.id;
            if (cardId == null) {
                return this.props.history.push(config.ROUTES.MY_CARDS)
            } else {
                for (const card of this.props.stores.AuthStore.currentUser.card_lines) {
                    if (card.id.toString() === cardId) {
                        return this.props.stores.CardStore.setCard(card);
                    }
                }
                return this.props.history.push(config.ROUTES.MY_CARDS)
            }
        }
    }

    componentWillUnmount() {
        this.props.stores.CardStore.clearCard();
    }

    render() {
        const {CardStore} = this.props.stores;
        const {currentCard} = CardStore;
        if (currentCard) {
            const companyId = CardStore.currentCard.loyalty_company_id;
            const company = CardStore.findCompany(companyId);
            if (company && company.allow_transfer) {
                if (this.tabs[this.tabs.length - 1].index !== config.SUBMENU_ITEMS.MY_CARDS.TRANSFER_RECORDS) {
                    this.tabs.push({
                        index: config.SUBMENU_ITEMS.MY_CARDS.TRANSFER_RECORDS,
                        tabTitle: "Transfer Records",
                        component: <TransferRecords/>,
                    });
                }
            }
        }
        return <PageTemplate tabs={this.tabs}/>
    }

}

export default withRouter(CardDetails)
