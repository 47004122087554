import React from "react";
import {withRouter} from "react-router";
import config from "../../config";
import PageTemplate from "../../components/PageTemplate";
import MyProfile from "./MyProfile";
import ResetPassword from "./ResetPassword";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.tabs = [
            {
                index: config.SUBMENU_ITEMS.PROFILE.MY_PROFILE,
                tabTitle: "My Profile",
                component: <MyProfile/>,
            },
            {
                index: config.SUBMENU_ITEMS.PROFILE.RESET_PASSWORD,
                tabTitle: "Reset Password",
                component: <ResetPassword/>,
            }
        ];
    }

    render() {
        return <PageTemplate tabs={this.tabs}/>
    }

}

export default withRouter(Profile);
