import React from "react";
import styles from "./index.module.less";
import {
    Col,
    Descriptions,
    Form,
    Input,
    Modal,
    Row,
    Spin,
    Tooltip, Upload
} from "antd";
import {inject, observer} from "mobx-react";
import moment from "moment";
import BizexBtn from "../../../components/BizexBtn";
import config from "../../../config";
import {editLoyaltyCustomer, uploadAvatar} from "../../../service";
import {User} from "../../../entities";
import {message} from "../../../lib";
import * as numeral from "numeral";
import UserOutlined from "@ant-design/icons/es/icons/UserOutlined";
import LoadingOutlined from "@ant-design/icons/es/icons/LoadingOutlined";
import PlusOutlined from "@ant-design/icons/es/icons/PlusOutlined";


// function getBase64(img, callback) {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// }

@inject('stores')
@observer
class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showEditModal: false,
            disableSubmit: true,
            daysList: [],
            imgUrl: "",
            uploading: false,
        };
        this.formRef = React.createRef();
    }

    handleEditUserInfoClick = () => {
        this.getDaySelectOptions();
        this.setState({showEditModal: true, disableSubmit: false,});
    };


    handleCancelClick = () => {
        this.formRef.current.resetFields();
        this.setState({
            showEditModal: false,
            imgUrl: ""
        });
    };

    handleSubmit = value => {
        this.setState({loading: true, disableSubmit: true,}, async () => {
            try {
                const params = {
                    email: value.email,
                    avatar: this.state.imgUrl,
                };
                const res = await editLoyaltyCustomer(params);
                if (res.error) throw res.error;
                const {data} = res;

                const user = User.buildFromData(data);
                this.props.stores.AuthStore.setUser(user);
                this.handleCancelClick();
                message.success("Save success")
            } catch (e) {
                message.error(e.message);
                this.setState({disableSubmit: false})
            } finally {
                this.setState({loading: false})
            }
        })
    };


    getDaySelectOptions = () => {
        const year = this.formRef.current.getFieldValue("year");
        const month = this.formRef.current.getFieldValue("month");
        let daysList = [];

        if (!year || !month) {
            this.setState({daysList});
            this.formRef.current.setFieldsValue({day: undefined})
        } else {
            const momentObj = moment(`${year} ${month}`, "YYYY MM");
            const dayNumber = momentObj.daysInMonth();
            // console.log(dayNumber);
            for (let i = 1; i <= dayNumber; i++) {
                daysList.push(numeral(i).format("00"));
            }
            if (this.formRef.current.getFieldValue("day") > dayNumber) {
                this.formRef.current.setFieldsValue({day: dayNumber});
            }
            this.setState({daysList});
        }
    };

    uploadAvatar = () => {
        this.setState({uploading: true})
    };

    uploadAction = e => {
        this.setState({uploading: true,}, async () => {
            try {
                const res = await uploadAvatar({avatar: e.file});
                if (res.error) throw res.error;
                const {data} = res;
                this.setState({imgUrl: data});
            } catch (e) {
                console.log(e);
                message.error("Uploading avatar failed");
            } finally {
                this.setState({uploading: false})
            }
        })

    };

    render() {
        const {currentUser} = this.props.stores.AuthStore;
        // console.log(currentUser.avatar)
        // const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;

        const uploadButton = (
            <div className={styles.avatarUploader}>
                {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
                <p>Upload</p>
            </div>
        );

        return (
            <div className={styles.wrap}>
                <div>
                    <Spin className={styles.spin} spinning={this.state.loading}/>
                </div>
                <div>
                    <h1 className={styles.title}>My Profile</h1>

                    <Tooltip title="Edit User Info">
                        <BizexBtn
                            btnType={config.BIZEX_BTN_TYPES.PINK}
                            className={styles.updateBtn}
                            onClick={this.handleEditUserInfoClick}
                        >
                            Update
                        </BizexBtn>
                    </Tooltip>
                </div>
                <Row >
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <div className={styles.avatarWrap}>
                            <span>
                                {
                                    currentUser.avatar ?
                                    <div className={styles.avatar} style={{backgroundImage: `url(${currentUser.avatar})`}}/>
                                    :
                                    <UserOutlined className={styles.avatar} />
                                }
                            </span>
                            <p>{currentUser.first_name ? currentUser.first_name : ""} {currentUser.last_name ? currentUser.last_name : ""}</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Descriptions title={null} layout="vertical" column={1} className={styles.descriptions}>
                            <Descriptions.Item label={<span className={styles.label}>Name</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentUser.first_name ? currentUser.first_name : ""} {currentUser.last_name ? currentUser.last_name : ""}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className={styles.label}>Email</span>}>
                                <span className={styles.descriptionsItem}>
                                    {currentUser.email ? currentUser.email : '-'}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.showEditModal}
                    onCancel={this.handleCancelClick}
                    title="Edit User Info"
                    footer={null}
                    forceRender={true}
                >
                    <Form
                        ref={this.formRef}
                        onFinish={this.handleSubmit}
                        colon={true}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        initialValues={{
                            name: currentUser.first_name + " " + currentUser.last_name,
                            email: currentUser.email,
                        }}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                        >
                            <span>{currentUser.first_name + " " + currentUser.last_name}</span>
                            {/*<Input disabled={true} placeholder="Name"/>*/}
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{required: true, message: "Email is required"}]}
                        >
                            <Input placeholder={"Email"}/>
                        </Form.Item>

                        <Form.Item
                            label="Avatar"
                        >
                            <Upload
                                customRequest={this.uploadAction}
                                onChange={this.uploadAvatar}
                                // action={this.uploadAction()}
                                showUploadList={false}
                            >
                                <div className={styles.avatarUploaderWrap}>
                                    {this.state.imgUrl ? <img src={this.state.imgUrl} alt="avatar"/> : uploadButton}
                                </div>
                            </Upload>
                        </Form.Item>

                        <div className={styles.buttonGroup}>
                            <BizexBtn
                                btnType={config.BIZEX_BTN_TYPES.BLUE_GHOST}
                                onClick={this.handleCancelClick}
                                className={styles.cancelBtn}
                            >
                                Cancel
                            </BizexBtn>
                            <BizexBtn
                                disabled={this.state.disableSubmit}
                                loading={this.state.disableSubmit}
                                htmlType="submit"
                                className={styles.cancelBtn}
                            >
                                Submit
                            </BizexBtn>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default MyProfile;
