import React from 'react';
import {formatDate, getRounding2, message} from "../../../../lib";
import styles from "./index.module.less";
import {listLoyaltyTransaction} from "../../../../service";
import {inject, observer} from "mobx-react";
import {DatePicker, Descriptions, Modal, Table} from "antd/lib/index";
import config from "../../../../config";
import * as numeral from "numeral";
import BizexBtn from "../../../../components/BizexBtn";
import PageLoading from "../../../../components/PageLoading";

@inject('stores')
@observer
class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            transactions: [],
            page: 1,
            count: 0,
            viewingTrans: {},
            dateFrom: null,
            dateTo: null,
        };
        this.pageSize = config.TRANSACTIONS_PAGE_SIZE;
        this.dataFormat = "DD/MM/YYYY";
        this.columns = [
            {
                title: "#",
                dataIndex: "id",
                key: "id",
                align: "center",
            },
            {
                title: 'Invoice Id',
                dataIndex: 'invoice_id',
                key: 'invoice_id',
                // width: '6%',
                align: 'center',
            },
            {
                title: "Date",
                dataIndex: "invoice_date",
                key: "invoice_date",
                align: 'center',
                render: (text) => {
                    return formatDate(text);
                }
            },
            {
                title: "Gross Amount",
                dataIndex: "invoice_gross_amount",
                key: "invoice_gross_amount",
                align: "right",
                render: (text) => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Loyalty Amount",
                dataIndex: "loyalty_amount",
                key: "loyalty_amount",
                align: 'right',
                render: (text) => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Redeemed Amount",
                dataIndex: "redeem_amount",
                key: "redeem_amount",
                align: "right",
                render: text => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Sale Value",
                dataIndex: "loyalty_point",
                key: "loyalty_point",
                align: "right",
                render: text => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Action",
                key: "action",
                align: 'center',
                width: '6%',
                render: (text, record) => (
                    <BizexBtn
                        onClick={() => this.handleViewTransactionClick(record)}
                        // btnType={config.BIZEX_BTN_TYPES.PINK}
                        btnType={config.BIZEX_BTN_TYPES.BLUE_GHOST}
                    >
                        View
                    </BizexBtn>
                )
            }
        ];

        this.transColumns = [
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                align: 'center',
                width: "15%",
            },
            {
                title: "Unit Price",
                dataIndex: "unit_price",
                key: 'unit_price',
                align: 'right',
                render: (text) => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Quantity",
                dataIndex: "line_qty",
                key: "line_qty",
                align: 'center',
            },
            {
                title: "Amount",
                dataIndex: "line_amount",
                key: "line_amount",
                align: 'right',
                render: text => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Unit Loyalty Amount",
                dataIndex: "unit_loyalty_amount",
                key: 'unit_loyalty_amount',
                align: 'right',
                render: text => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: 'Total Loyalty Amount',
                dataIndex: "line_loyalty_amount",
                key: "line_loyalty_amount",
                align: 'right',
                render: text => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
            {
                title: "Sale Value",
                dataIndex: "line_loyalty_point",
                key: "line_loyalty_point",
                align: "right",
                render: text => {
                    return numeral(getRounding2(text)).format('$0,0.00');
                }
            },
        ];
    }

    handleViewTransactionClick = record => {
        this.setState({viewingTrans: record, showModal: true})

        // this.setState({loading: true}, async () => {
        //     try {
        //         const {id} = record;
        //         const res = await getATransactionById(id);
        //         if (res.error) throw res.error;
        //         const {data} = res;
        //         if (!data) throw "Error occurs while reading data:" + data.toString();
        //         this.setState({viewingTrans: data, showModal: true})
        //     } catch (e) {
        //         message.error(e.message)
        //     } finally {
        //         this.setState({loading: false})
        //     }
        // })
    };

    handleCloseModalClick = () => {
        this.setState({
            showModal: false,
            viewingTrans: {},
        })
    };

    onChangePage = (pagination, filters, sorter) => {
        this.setState({loading: true, page: pagination.current}, async () => {
            await this.loadDocument();
            this.setState({loading: false})
        })
    };

    loadDocument  = async () => {
        try {
            const page = this.state.page;
            const pageSize = this.pageSize;
            const dateFrom = this.state.dateFrom ? this.state.dateFrom.format("YYYY-MM-DD") : '';
            const dateTo = this.state.dateTo ? this.state.dateTo.format("YYYY-MM-DD") : '';
            const cardId = this.props.stores.CardStore.currentCard.id;
            const res = await listLoyaltyTransaction("", cardId, page, pageSize, dateFrom, dateTo);
            if (res.error) throw res.error;
            const {data} = res;
            if (data.count) {
                this.setState({count: data.count});
            }
            if (data.list) {
                this.setState({transactions: data.list, loading: false})
            } else {
                this.setState({transactions: [], loading: false})
            }
        } catch (e) {
            message.error(e.message);
        }
    };

    changeDateRange = (value, type) => {
        if (type === 0) {
            this.setState({dateFrom: value, loading: true},  async () => {
                await this.loadDocument();
                this.setState({loading: false});
            })
        } else if (type === 1) {
            this.setState({dateTo: value, loading: true}, async () => {
                await this.loadDocument();
                this.setState({loading: false});
            })
        }
    };

    componentDidMount() {
        this.setState({loading: true}, async () => {
            await this.loadDocument();
            this.setState({loading: false});
        })
    };

    render() {
        return (
            <div className={styles.wrap}>
                <PageLoading spinning={this.state.loading}/>
                <h1 className={styles.title}>
                    {/*<img src={require("../../../img/blue_logo.png")} alt="logo" className={styles.titleLogo}/>*/}
                    Transactions
                </h1>
                <div className={styles.filterWrap}>
                    <span>
                        <span className={styles.filterTitle}>Date filter:</span>
                        <DatePicker
                            format={this.dataFormat}
                            placeholder={"Date From"}
                            onChange={(value) => this.changeDateRange(value, 0)}
                            value={this.state.dateFrom}
                            disabledDate={value => {
                                return value && this.state.dateTo && value > this.state.dateTo
                            }}
                        />
                         -
                        <DatePicker
                            format={this.dataFormat}
                            placeholder={"Date to"}
                            onChange={(value) => this.changeDateRange(value, 1)}
                            value={this.state.dateTo}
                            disabledDate={value => {
                                return value && value < this.state.dateFrom
                            }}
                        />
                    </span>
                </div>
                <Table
                    rowKey={(r, k) => k}
                    columns={this.columns}
                    dataSource={this.state.transactions}
                    pagination={{
                        pageSize: this.pageSize,
                        total: this.state.count,
                        page: this.state.page,
                    }}
                    tableLayout={"fixed"}
                    onChange={this.onChangePage}
                    className={styles.table}
                />
                <Modal
                    visible={this.state.showModal}
                    // footer={
                    //     <Button key="back" onClick={this.handleCloseModalClick}>
                    //         Close
                    //     </Button>
                    // }
                    footer={null}
                    onCancel={this.handleCloseModalClick}
                    title="Invoice"
                    width={950}
                >
                    <Descriptions column={2}>
                        <Descriptions.Item label={
                            <span className={styles.modalDescriptionsLabel}>
                                Invoice Id
                            </span>
                        }>
                            {this.state.viewingTrans.invoice_id}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <span className={styles.modalDescriptionsLabel}>
                                Date
                            </span>
                        }>
                            {formatDate(this.state.viewingTrans.invoice_date)}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <span className={styles.modalDescriptionsLabel}>
                                Sale Amount
                            </span>
                        }>
                            {"$" + getRounding2(this.state.viewingTrans.invoice_gross_amount)}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <span className={styles.modalDescriptionsLabel}>
                                Loyalty Amount
                            </span>
                        }>
                            {"$" + getRounding2(this.state.viewingTrans.loyalty_amount)}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <span className={styles.modalDescriptionsLabel}>
                                Redeemed Amount
                            </span>
                        }>
                            {"$" + getRounding2(this.state.viewingTrans.redeem_amount)}
                        </Descriptions.Item>
                    </Descriptions>
                    <Table
                        className={styles.table}
                        pagination={false}
                        columns={this.transColumns}
                        dataSource={this.state.viewingTrans.loyalty_transaction_lines}
                        rowKey={(r, k) => k}
                        tableLayout={"fixed"}
                    />
                </Modal>
            </div>
        )
    }

}

export default Transactions;
