import {message as msg} from 'antd';
import config from "../config";
import * as numeral from "numeral";
import moment from 'moment';
import CacheStorage from './cache-storage';

let dd_i = 0;
export const dd = (...ps) => {
    if (!config.debug) return;
    dd_i++;
    ps.unshift("Debug " + dd_i + "[" + new Date() + "]");
    // console.log(...ps);
    let debug = console.log.bind(window.console);
    debug(...ps);
};

export const message = {
    globalError: function (...ps) {
        msg.error(...ps);
        dd("Message.error", ...ps);

        // Write back to server
        (async () => {
            try{
                // await writeLogToServer(ps, 'message.error');
                window.location.href = '/';
            }
            catch (e) {}

        })();
    },
    error: function (...ps) {
        msg.error(...ps);
        dd("Message.error", ...ps);

        // Write back to server
        // (async () => {
        //     try{
        //         await writeLogToServer(ps, 'message.error');
        //     }
        //     catch (e) {}
        //
        // })();
    },
    warning: function (...ps) {
        msg.warning(...ps);
        dd("Message.warning", ...ps);
    },
    info: function (...ps) {
        msg.info(...ps);
        dd("Message.info", ...ps);
    },
    success: function (...ps) {
        msg.success(...ps);
        dd("Message.success", ...ps);

        // Write back to server
        // (async () => {
        //     try{
        //         await writeLogToServer(ps, 'message.success');
        //     }
        //     catch (e) {}
        //
        // })();
    },
};

export const getRounding = (number, digit) => {
    if ( typeof digit === 'undefined')  digit = 2;
    let final, isNegativeNum;
    if (number < 0) {
        isNegativeNum = true;
        number = - number;
    }
    if (digit > 4) {
        let num = Math.pow(10, digit);
        final = Math.round(numeral(number).value() * num) / num;
    } else {
        let num = Math.pow(10, digit + 4);
        let temp = numeral(number).value() * num;
        for (let i = 4  ; i > 0; i--) {
            temp = Math.round(temp) / 10;
        }
        final = Math.round(temp) / Math.pow(10, digit);
    }
    if(isNegativeNum) {
        final = -final;
    }
    //console.log('getRounding(' + number.toString() + ', ' + digit.toString() + ') = ' + final.toString());
    return final;
};

export const getRounding2 = (number) => getRounding(number, 2);

export const formatDate = date => moment(date, "YYYY-MM-DD HH:mm:ss Z").format('DD/MM/YYYY HH:mm:ss a');

export const apiTimeStrToMoment = dateStr => moment(dateStr, "YYYY-MM-DD HH:mm:ss Z");

export {CacheStorage};
