// import _ from 'lodash';
import EntityModel from "../lib/entity";

const Entity = EntityModel.define("user", [
    'id',
    // "loyalty_number",
    "first_name",
    "last_name",
    "active",
    // "address_line_1",
    // "address_line_2",
    // "address_line_3",
    "email",
    // "phone_1",
    // "phone_2",
    // "birthday",
    "passwd",
    // "sub_customer_id",
    // "balance",
    // "to_email",
    // "roles",
    "card_lines",
    "avatar",
    "last_login_time",

    // "superAdmin",
    // "client",
    // "roles",
    // "privileges",
    // "menus",
    // "products",
]);

Entity.prototype.getName = function () {
    return [this.first_name, this.last_name];
};

Entity.buildFromAPI = function (data) {
    const userinfo = data.userinfo;
    return this.build(userinfo);
};

Entity.buildFromData = function (user) {
    return this.build(user);
};

// Entity.prototype.isManager = function () {
//     return true;
// }

export default Entity;
