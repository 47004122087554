// 'use strict';
const _ = require('lodash');

function camelizeIf(str, condition) {
    let result = str;

    if (condition) {
        result = camelize(str);
    }

    return result;
}
exports.camelizeIf = camelizeIf;

// function underscoredIf(str, condition) {
//     let result = str;
//
//     if (condition) {
//         result = underscore(str);
//     }
//
//     return result;
// }
// exports.underscoredIf = underscoredIf;

function isPrimitive(val) {
    const type = typeof val;
    return type === 'string' || type === 'number' || type === 'boolean';
}
exports.isPrimitive = isPrimitive;

// Same concept as _.merge, but don't overwrite properties that have already been assigned
function mergeDefaults(a, b) {
    return _.mergeWith(a, b, objectValue => {
        // If it's an object, let _ handle it this time, we will be called again for each property
        if (!_.isPlainObject(objectValue) && objectValue !== undefined) {
            return objectValue;
        }
    });
}
exports.mergeDefaults = mergeDefaults;

// An alternative to _.merge, which doesn't clone its arguments
// Cloning is a bad idea because options arguments may contain references to sequelize
// models - which again reference database libs which don't like to be cloned (in particular pg-native)
function merge() {
    const result = {};

    for (const obj of arguments) {
        _.forOwn(obj, (value, key) => {
            if (value !== undefined) {
                if (!result[key]) {
                    result[key] = value;
                } else if (_.isPlainObject(value) && _.isPlainObject(result[key])) {
                    result[key] = merge(result[key], value);
                } else if (Array.isArray(value) && Array.isArray(result[key])) {
                    result[key] = value.concat(result[key]);
                } else {
                    result[key] = value;
                }
            }
        });
    }

    return result;
}
exports.merge = merge;

function spliceStr(str, index, count, add) {
    return str.slice(0, index) + add + str.slice(index + count);
}
exports.spliceStr = spliceStr;

function camelize(str) {
    return str.trim().replace(/[-_\s]+(.)?/g, (match, c) => c.toUpperCase());
}
exports.camelize = camelize;

// function underscore(str) {
//     return inflection.underscore(str);
// }
// exports.underscore = underscore;

function cloneDeep(obj, onlyPlain) {
    obj = obj || {};
    return _.cloneDeepWith(obj, elem => {
        // Do not try to customize cloning of arrays or POJOs
        if (Array.isArray(elem) || _.isPlainObject(elem)) {
            return undefined;
        }

        // If we specified to clone only plain objects & arrays, we ignore everyhing else
        // In any case, don't clone stuff that's an object, but not a plain one - fx example sequelize models and instances
        if (onlyPlain || typeof elem === 'object') {
            return elem;
        }

        // Preserve special data-types like `fn` across clones. _.get() is used for checking up the prototype chain
        if (elem && typeof elem.clone === 'function') {
            return elem.clone();
        }
    });
}
exports.cloneDeep = cloneDeep;

function now(dialect) {
    const d = new Date();
    d.setMilliseconds(0);
    return d;
}
exports.now = now;

// Note: Use the `quoteIdentifier()` and `escape()` methods on the
// `QueryInterface` instead for more portable code.

const TICK_CHAR = '`';
exports.TICK_CHAR = TICK_CHAR;

function addTicks(s, tickChar) {
    tickChar = tickChar || TICK_CHAR;
    return tickChar + removeTicks(s, tickChar) + tickChar;
}
exports.addTicks = addTicks;

function removeTicks(s, tickChar) {
    tickChar = tickChar || TICK_CHAR;
    return s.replace(new RegExp(tickChar, 'g'), '');
}
exports.removeTicks = removeTicks;

/**
 * Receives a tree-like object and returns a plain object which depth is 1.
 *
 * - Input:
 *
 *  {
 *    name: 'John',
 *    address: {
 *      street: 'Fake St. 123',
 *      coordinates: {
 *        longitude: 55.6779627,
 *        latitude: 12.5964313
 *      }
 *    }
 *  }
 *
 * - Output:
 *
 *  {
 *    name: 'John',
 *    address.street: 'Fake St. 123',
 *    address.coordinates.latitude: 55.6779627,
 *    address.coordinates.longitude: 12.5964313
 *  }
 *
 * @param {Object} value an Object
 * @returns {Object} a flattened object
 * @private
 */
function flattenObjectDeep(value) {
    if (!_.isPlainObject(value)) return value;
    const flattenedObj = {};

    function flattenObject(obj, subPath) {
        Object.keys(obj).forEach(key => {
            const pathToProperty = subPath ? `${subPath}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flattenObject(obj[key], pathToProperty);
            } else {
                flattenedObj[pathToProperty] = _.get(obj, key);
            }
        });
        return flattenedObj;
    }

    return flattenObject(value, undefined);
}
exports.flattenObjectDeep = flattenObjectDeep;

