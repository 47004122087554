import React from "react";
import styles from "./index.module.less";

export default class BrandLogo extends React.Component {
    render() {
        return (
            <div {...this.props}>
                <img alt="logo" src={require("../../img/logo.png")} className={styles.logo}/>
            </div>
        )
    }
}
