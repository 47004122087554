import React from 'react';
import {Spin} from "antd";
import styles from "./index.module.less";

export default class PageLoading extends React.Component {
    render() {
        return (
            <Spin
                spinning={this.props.spinning}
                className={styles.spin}
            />
        )
    }
}
