import _ from "lodash";
import * as Utils from './utils';

class Model {
    constructor(values) {
        this.dataValues = {};

        if (_.isObject(values)) {
            Object.keys(values).map(key => {
                if (typeof this.rawAttributes[key] != 'undefined') {
                    this[key] = values[key];
                }
            })
        }
    }

    get(k) { return this.dataValues[k]; }
    set(k, v){ this.dataValues[k] = v; return this; }
    toJSON() { return _.cloneDeep( this.dataValues ); }
    toString() { return JSON.stringify(this.toJSON()); /*// return `[Object Instance :${this.constructor.name}]`;*/ }

    static build(values){
        return new this(values);
    }

    static init(attributes, options) {
        this.entity = options.entity;
        const globalOptions = this.entity.options;
        options = Utils.merge(_.cloneDeep(globalOptions.define || {}), options);
        if (!options.modelName) {
            options.modelName = this.name;
        }
        if (options.modelName !== this.name) {
            Object.defineProperty(this, 'name', { value: options.modelName });
        }
        delete options.modelName;

        this.options = Object.assign({}, options);
        // if you call "define" multiple times for the same modelName, do not clutter the factory
        if (this.entity.isDefined(this.name)) {
            this.entity.modelManager.removeModel(this.entity.modelManager.getModel(this.name));
        }

        // Set Attributes
        this.rawAttributes = {};
        if ( _.isArray(attributes) && attributes.length > 0){
            attributes.map(item => {
                this.rawAttributes[item] = {};
            });
        }
        else if ( _.isObject(attributes) && Object.keys(attributes).length > 0){
            this.rawAttributes = attributes;
        }
        else{
            throw new Error("No attributes setting for the entity");
        }

        this.refreshAttributes();
    }

    static refreshAttributes() {
        const attributeManipulation = {};

        this.prototype._customGetters = {};
        this.prototype._customSetters = {};

        ['get', 'set'].forEach(type => {
            const opt = `${type}terMethods`;
            const funcs = _.clone(_.isObject(this.options[opt]) ? this.options[opt] : {});
            const _custom = type === 'get' ? this.prototype._customGetters : this.prototype._customSetters;

            _.each(funcs, (method, attribute) => {
                _custom[attribute] = method;

                if (type === 'get') {
                    funcs[attribute] = function() {
                        return this.get(attribute);
                    };
                }
                if (type === 'set') {
                    funcs[attribute] = function(value) {
                        return this.set(attribute, value);
                    };
                }
            });

            _.each(this.rawAttributes, (options, attribute) => {
                if (Object.prototype.hasOwnProperty.call(options, type)) {
                    _custom[attribute] = options[type];
                }

                if (type === 'get') {
                    funcs[attribute] = function() {
                        return this.get(attribute);
                    };
                }
                if (type === 'set') {
                    funcs[attribute] = function(value) {
                        return this.set(attribute, value);
                    };
                }
            });

            _.each(funcs, (fct, name) => {
                if (!attributeManipulation[name]) {
                    attributeManipulation[name] = {
                        configurable: true
                    };
                }
                attributeManipulation[name][type] = fct;
            });
        });

        this.prototype._hasCustomGetters = Object.keys(this.prototype._customGetters).length;
        this.prototype._hasCustomSetters = Object.keys(this.prototype._customSetters).length;

        for (const key of Object.keys(attributeManipulation)) {
            Object.defineProperty(this.prototype, key, attributeManipulation[key]);
        }

        this.prototype.rawAttributes = this.rawAttributes;
    }

}

export default Model;
