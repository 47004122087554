import api from "./api";
import config from "../config";

/**
 * Login to the loyalty card system
 * */
export const login = (username, password) => {
    const uri = password ? (username + '/' + password) : username;

    return api.request({
        url: `/brunton/loyalty_user/login/${uri}?nocache=${new Date().getTime()}`,
        method: 'get',
    })
};

/**
 * Logout from the loyalty card system
 * */
export const logout = () => {
    return api.request({
        url: `/brunton/loyalty_user/logout`,
        method: 'get',
    })
};

/**
 * Send verification code to loyalty user's email.
 * */
export const sendVerifyCode = email => {
    const fakeJWT = (new Date()).valueOf();
    return api.request({
        url: `/brunton/loyalty_user/send_code/${email}?jwt=${fakeJWT}`,
        method: 'get',
    })
};

/**
 * Reset loyalty customer's password by email and verification code
 * */
export const resetPasswordByEmailAndVerifyCode = data => {
  const fakeJWT = (new Date()).valueOf();
  return api.request({
        url: `/brunton/loyalty_user/reset_password?jwt=${fakeJWT}`,
        method: 'patch',
        data: data,
    })
};


/**
 * Get current loyalty user
 * */
export const getCurrentLoyaltyUser = () => {
    return api.request({
        url: `/brunton/loyalty_user/current_user`,
        method: 'get',
    })
};

/**
 * edit and update loyalty customer
 * */
export const editLoyaltyCustomer = (user) => {
    return api.request({
        url: `/brunton/loyalty_user/profile/edit`,
        data: user,
        method: 'patch',
    })
};

/**
 * List Loyalty Transaction by Loyalty Customer ID
 * */
export const listLoyaltyTransaction = (companyId, cardId, page, pageSize, dateFrom, dateTo) => {
    return api.request({
        url: `/loyalty/data/loyalty_transaction/list_for_customer?companyid=${companyId}&cardid=${cardId}&page=${page}&pagesize=${pageSize}&datefrom=${dateFrom}&dateto=${dateTo}`,
        method: 'get',
    })
};

/**
 * Get a Loyalty Transaction by ID
 * */
export const getATransactionById = id => {
    return api.request({
        url: `/loyalty/data/loyalty_transaction_self/${id}`,
        method: 'get'
    })
};

/**
 * List Loyalty Transfer
 */
export const listLoyaltyTransfer = (companyId, cardFrom, cardTo, dateFrom, dateTo, page, pageSize) => {
    return api.request({
        url: `/loyalty/data/loyalty_transfer_by_loyalty_customer/list?companyid=${companyId}&cardfrom=${cardFrom}&cardto=${cardTo}&datefrom=${dateFrom}&dateto=${dateTo}&page=${page}&pagesize=${pageSize}`,
        method: 'get'
    })
};

/**
 * Transfer loyalty credit
 */
export const transferLoyaltyCredit = (cardFrom, cardTo, amount) => {
    return api.request({
        url: `/loyalty/data/transfer_loyalty_credit?cardfrom=${cardFrom}&cardto=${cardTo}&amount=${amount}`,
        method: "post",
    })
};

/**
 * Edit Loyalty Card
 */
export const editLoyaltyCard = data => {
    return api.request({
        url: `/brunton/loyalty_user/card_profile/edit`,
        data,
        method: 'patch',
    })
};


/**
 * Upload loyalty user's avatar
 */
export const uploadAvatar = data => {
    const formData = new FormData();
    formData.append("avatar", data.avatar);
    return api.request({
        url: `/brunton/loyalty_user/upload_avatar`,
        data: formData,
        method: "post",
        headers: {'Content-Type': 'multipart/form-data'},
    })
};

/**
 * Get the upload url as action
 */
export const uploadAvatarUrl = () => {
    const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;
    return baseUrl + "/brunton/loyalty_user/upload_avatar";
};
