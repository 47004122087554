import api from './api';

const env = process.env.NODE_ENV;

let backEndBaseUrl;

if (env === 'production') {
    backEndBaseUrl = {
        dev: 'https://epac-loyalty-be.azurewebsites.net/',
        pro: 'https://epac-loyalty-be.azurewebsites.net/',
        // dev: 'https://pos-apis.azurewebsites.net/',
        // pro: 'https://pos-apis.azurewebsites.net/',
    };
} else if (env === 'development'){
    backEndBaseUrl = {
        // dev: 'https://pos-feature-loyalty.azurewebsites.net/',
        // pro: 'https://pos-feature-loyalty.azurewebsites.net/',
        // dev: 'https://pos-be-loyalty-backup.azurewebsites.net/',
        // pro: 'https://pos-be-loyalty-backup.azurewebsites.net/',
        dev: 'https://pos-loyalty-be.azurewebsites.net/',
        pro: 'https://pos-loyalty-be.azurewebsites.net/',
    };
} else {
    throw new Error('Invalid env value')
}

const frontEndUrlSymbol = () => {
    let urlArr = window.location.href.split('/');
    return urlArr.length > 2 ? urlArr[2] + '/' : '/';
};

export default {
    CURRENT_ENV: env,
    env: {
        production: 'production',
        development: 'development',
    },
    debug: true,    // Debug info
    api,
    baseUrl: backEndBaseUrl,

    frontEndUrlPreFix: frontEndUrlSymbol(),

    ROUTES: {
        // MY_ACCOUNT: "/",
        // TRANSACTIONS: "/transactions",
        MY_CARDS: "/",
        CARD_DETAILS: "/card/:id",
        CARD: "/card/",
        PROFILE: "/profile",
        LOGIN: "/login",
        RESET_PASSWORD: "/reset-password",
    },

    SUBMENU_ITEMS: {
        MY_CARDS: {
            MY_CARDS: "my_cards",
            TRANSACTIONS: "transactions",
            TRANSFER_RECORDS: "transfer_records",
        },
        PROFILE: {
            MY_PROFILE: "my_profile",
            RESET_PASSWORD: "reset_password",
        }
    },

    API_REQUEST_TIME_OUT: 25000,

    TRANSACTIONS_PAGE_SIZE: 20,

    TOKEN_SYMBOL: frontEndUrlSymbol() + "BIZEX_TOKEN",
    INFO_SYMBOL: frontEndUrlSymbol() + "BIZEX_INFO",

    ENCRYPT_KEY: "J6oUemKJ5xjfARff",
    ENCRYPT_IV: "toPvbTMV8vPDc1ik",

    BIZEX_BTN_TYPES: {
        PINK: "pink",
        BLUE: "blue",
        PINK_GHOST: "pinkGhost",
        BLUE_GHOST: "blueGhost",
        GREY_GHOST: "greyGhost",
    },
}
