import React from "react";
import {Button} from "antd/lib/index";
import config from "../../config";
import styles from "./index.module.less";

export default class BizexBtn extends React.Component {
    getClassName = () => {
        if (this.props.btnType === config.BIZEX_BTN_TYPES.BLUE) {
            return styles.blue;
        } else if (this.props.btnType === config.BIZEX_BTN_TYPES.BLUE_GHOST) {
            return styles.blueGhost;
        } else if (this.props.btnType === config.BIZEX_BTN_TYPES.PINK  || this.props.btnType == null) {
            return styles.pink;
        } else if (this.props.btnType === config.BIZEX_BTN_TYPES.PINK_GHOST) {
            return styles.pinkGhost;
        } else if (this.props.btnType === config.BIZEX_BTN_TYPES.GREY_GHOST) {
            return styles.greyGhost;
        } else {
            // console.log(this.props.btnType)
            throw new Error("Invalid btnType");
        }
    };


    render() {
        return (
            <span className={this.props.className} style={{display: "inline-block"}} title={this.props.title}>
                <Button
                    className={this.getClassName()}
                    onClick={this.props.onClick}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    htmlType={this.props.htmlType}
                    type={this.props.type ? this.props.type : "primary"}
                    shape={this.props.shape}
                    loading={this.props.loading}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    icon={this.props.icon}
                    danger={this.props.danger}
                    target={this.props.target}
                    href={this.props.href}
                    ghost={this.props.btnType === config.BIZEX_BTN_TYPES.BLUE_GHOST || this.props.btnType === config.BIZEX_BTN_TYPES.PINK_GHOST || this.props.btnType === config.BIZEX_BTN_TYPES.GREY_GHOST}
                >
                    {this.props.children}
                </Button>
            </span>

        )
    }
}
