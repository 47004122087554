
import axios from 'axios'
import config from '../config';
// import {writeLogToServer} from './index';
import { dd } from "../lib";
// import history from "../MyMenu/history";

const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;

class HttpRequest {
    constructor(baseUrl = '') {
        this.baseUrl = baseUrl;
        this.queue = {};
    }

    setStore(store) {
        this.store = store;
    }

    getInsideConfig(headers) {
        let token = this.store.AuthStore.getToken? this.store.AuthStore.getToken : '';
        return {
            baseURL: this.baseUrl,
            headers: {
                Authorization: token,
                //
                ...headers,
            },
            withCredentials: true,
            timeout: config.API_REQUEST_TIME_OUT,
        };
        // return config
    }

    resetBaseUrl(url) {
        this.baseUrl = url;
    }

    destroy(url) {
        delete this.queue[url];
    }

    interceptors(instance, url) {

        instance.interceptors.request.use(config => {
            config.metadata = { startTime: new Date()};
            this.queue[url] = true;
            return config;
        }, error => {
            return Promise.reject(error);
        });

        instance.interceptors.response.use(res => {
            this.destroy(url);
            // console.log(res)
            const { data, status, headers } = res;

            res.config.metadata.endTime = new Date();

            if ( status !== 200){
                throw new Error("Access API failure, Status Code :" + status)
            }
            if (headers && headers.authorization) {
                data.token = headers.authorization;
            }
            if (data.code) {
                if ( data.code === 200){
                    data.error = null;
                } else if (data.code === 401) {
                    data.error = new Error(data.msg);
                    data.error.code = data.code;

                    this.store.AuthStore.logoutLocally();   //  delete localStorage but do not send logout request
                    // this.store.AuthStore.logout();
                    // console.log("from api.js: logout executed!!! ")
                } else {
                    data.error = new Error(data.msg);
                    data.error.code = data.code;
                }
                return data;
            } else {
                return data;
            }

        }, error => {
            this.destroy(url);
            return Promise.reject(error)
        })
    }
    request(options) {
        if (config.CURRENT_ENV === config.env.development) {
            dd('API request sending to ', options.url);
        }
        const instance = axios.create();
        const {headers, ...res} = options;
        options = Object.assign(this.getInsideConfig(headers), res);
        this.interceptors(instance, options.url);
        return instance(options)
    }
}


const api = new HttpRequest(baseUrl);

export default api;

