import React from "react";
import styles from "./index.module.less";
import {Form, Input, Result, Spin} from "antd";
import {LockOutlined,UserOutlined,CheckCircleOutlined,} from '@ant-design/icons';
import {withRouter} from "react-router";
import config from "../../config";
import {message} from "../../lib";
import {resetPasswordByEmailAndVerifyCode, sendVerifyCode} from "../../service";
import BizexBtn from "../../components/BizexBtn";
import BrandLogo from "../../components/BrandLogo";

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            valid: true,
            verifyCodeBtnTxt: "Send Verification Code",
            disableVerifyCodeBtn: false,
            ok: false,
            submitLoading: false,
        };
        this.formRef = React.createRef();
        this.delay = 60;
    }

    // componentDidMount() {
        // console.log(this.props)
        // console.log(this.props.location.search)
    // }

    componentWillUnmount() {
        clearInterval();
    }

    goToSignIn = () => {
        this.props.history.push(config.ROUTES.LOGIN)
    };

    submitReset = values => {
        console.log(values);
        this.setState({submitLoading: true}, async () => {
            try {
                const data = {
                    email: values['email'],
                    code: values['verifyCode'].trim(),
                    new_password: values['newPassword'],
                };
                const res = await resetPasswordByEmailAndVerifyCode(data);
                if (res.error) throw res.error;
                this.setState({ok: true});
            } catch (e) {
                message.error(e.message)
            } finally {
                this.setState({submitLoading: false});
            }
        })

    };

    getVerifyCode = () => {
        this.formRef.current.validateFields(["email"]).then(values => {
            this.setState({
                disableVerifyCodeBtn: true,
            }, async () => {
                const email = values["email"];
                let counter = this.delay;
                const timer = setInterval(() => {
                    counter--;
                    this.setState({verifyCodeBtnTxt: `Resend (in ${counter}s)`});
                    if (counter === 0) {
                        clearInterval(timer);
                        this.setState({
                            verifyCodeBtnTxt: "Send Verification Code",
                            disableVerifyCodeBtn: false,
                        });
                    }
                }, 1000);

                try {
                    const res = await sendVerifyCode(email);
                    if (res.error) throw res.error;
                    message.success("The verify code has been send to your registered email. Please check your email.");
                } catch (e) {
                    clearInterval(timer);
                    if (e.code === 900) {
                        message.error("Invalid email.")
                    } else {
                        message.error(e.message);
                    }
                    this.setState({
                        verifyCodeBtnTxt: "Send Verification Code",
                        disableVerifyCodeBtn: false,
                    });
                }
            })
        })
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.wrap}>
                    <BrandLogo/>
                    {/*<img alt="logo" src={require("../../img/logo.png")} className={styles.logo}/>*/}
                    {/*<p className={styles.title}>L o y a l t y&nbsp;&nbsp;&nbsp;&nbsp;P o r t a l</p>*/}
                    {/*<p className={styles.p}>Reset password</p>*/}
                    {
                        this.state.ok ?
                        <div>
                            <Result
                                status="success"
                                icon={<img className={styles.resultIcon} src={require("../../img/reset_ok.png")} alt="OK"/>}
                                title={<p className={styles.resultTitle}>Congratulations!<br/>You have reset password successfully!</p>}
                                // title={<p>Congratulations!</p>Success! Please login with your new password.}
                                extra={[
                                    <BizexBtn
                                        className={styles.rightButton}
                                        onClick={this.goToSignIn}
                                    >
                                        Go to Sign In
                                    </BizexBtn>
                                ]}
                            />
                        </div>
                        :
                        <div className={styles.content}>
                            <Form autoComplete="off" onFinish={this.submitReset} ref={this.formRef}>
                                <Form.Item name="email" rules={[{required: true, message: 'Please input your email.'}]}>
                                    <Input
                                        prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        placeholder="Email"
                                    />
                                </Form.Item>
                                <Form.Item /*style={{width: '49%', marginRight: "2%", display: "inline-block"}}*/ name="verifyCode" rules={[{required: true, message: "Please input verify code."}]}>
                                    <Input
                                        allowClear
                                        placeholder="Verification Code"
                                        prefix={<CheckCircleOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    />
                                </Form.Item>
                                <BizexBtn
                                    btnType={config.BIZEX_BTN_TYPES.PINK}
                                    // disabled={true}
                                    disabled={this.state.disableVerifyCodeBtn}
                                    onClick={this.getVerifyCode}
                                    className={styles.verifyBtn}
                                >
                                    {this.state.verifyCodeBtnTxt}
                                </BizexBtn>

                                <Form.Item name="newPassword" rules={[{required: true, message: "Please input new password."}]}>
                                    <Input.Password
                                        prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        placeholder="New Password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    rules={[
                                        {required: true, message: "Please confirm the password."},
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords you entered do not match.');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        type="password"
                                        placeholder="Confirm Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <BizexBtn
                                        htmlType="submit"
                                        className={styles.rightButton}
                                        btnType={config.BIZEX_BTN_TYPES.PINK}
                                        loading={this.state.submitLoading}
                                        disabled={this.state.submitLoading}
                                    >
                                        Submit
                                    </BizexBtn>
                                    <BizexBtn
                                        onClick={this.goToSignIn}
                                        btnType={config.BIZEX_BTN_TYPES.BLUE}
                                        className={styles.leftButton}
                                    >
                                        Back to Sign In
                                    </BizexBtn>
                                </Form.Item>
                            </Form>
                            <div>
                                <Spin spinning={this.state.loading}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(ForgetPassword);
