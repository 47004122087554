import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {UserOutlined,LockOutlined,} from  '@ant-design/icons';
import {Alert, Checkbox, Form, Input, Spin,} from "antd";
import {login} from "../../service";
import {User} from '../../entities';
import config from "../../config";
import {withRouter} from "react-router";
import styles from "./index.module.less";
import CryptoJS from "crypto-js";
import moment from "moment";
import BizexBtn from "../../components/BizexBtn";
import BrandLogo from "../../components/BrandLogo";

@inject('stores')
@observer
class Login extends Component {
    state = {
        loading: false,
        errorMsg: "",
        rememberMe: false,
    };

    formRef = React.createRef();

    componentDidMount() {
        const info = localStorage.getItem(config.INFO_SYMBOL);
        if (info) {
            const infoObj = JSON.parse(this.decrypt(info));
            if (infoObj.expire && moment().format("YYYYMMDD") < infoObj.expire) {
                this.setState({rememberMe: true});
                const username = infoObj.user;
                const password = infoObj.pwd;
                this.formRef.current.setFieldsValue({username , password})
            } else {
                localStorage.removeItem(config.INFO_SYMBOL);
            }
        }
    }

    initialLocalState = () => {
        this.setState({
            loading: false,
            errorMsg: "",
        })
    };

    handleSignInAgain = (e) => {
        // e.preventDefault();
        this.props.stores.AuthStore.logoutLocally();
        this.initialLocalState();
    };

    handleSubmitLogin = (values) => {
        // console.log(values);
        // e.preventDefault();

        this.setState({
            loading: true,
        }, async () => {
            const username = values.username;
            const password = values.password;
            await this.initLogin({username, password});
            this.setState({
                loading: false,
            })
        })
    };

    clickForgotPassword = () => {
        this.props.history.push(config.ROUTES.RESET_PASSWORD)
    };

    decrypt = word => {
        const iv = config.ENCRYPT_IV;
        const key = CryptoJS.enc.Utf8.parse(config.ENCRYPT_KEY);
        const decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7, iv: iv});
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    };

    encrypt = word => {
        const iv = config.ENCRYPT_IV;
        const key = CryptoJS.enc.Utf8.parse(config.ENCRYPT_KEY);
        const srcs = CryptoJS.enc.Utf8.parse(word);
        const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7, iv: iv});
        return encrypted.toString();
    };

    initLogin = async value => {
        try {
            const {setUser, setToken, saveToken,} = this.props.stores.AuthStore;
            let res = await login(value.username, value.password);
            if (res.error) throw res.error;
            if (res.token) {
                setToken(res.token);
                saveToken(res.token);
            } else {
                throw new Error("User token not returned");
            }
            if (this.state.rememberMe) {
                const info = this.encrypt(JSON.stringify({user: value.username, pwd: value.password, expire: moment().add(15, 'd').format("YYYYMMDD")}));
                // console.log(info)
                localStorage.setItem(config.INFO_SYMBOL, info);
            } else {
                localStorage.removeItem(config.INFO_SYMBOL);
            }
            const {data} = res;
            const user = User.buildFromAPI(data);
            setUser(user);
            const {isLogin} = this.props.stores.AuthStore;
            if (isLogin) {
                // console.log("Going to push history to: ", config.ROUTES.MY_ACCOUNT)
                // console.log(this.props.history)
                this.props.history.push(config.ROUTES.MY_CARDS);
            }
        } catch (e) {
            // message.error("Error: " + e.message);
            this.setState({errorMsg: e.message})
        } finally {
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.wrap}>
                    <BrandLogo/>
                    <p className={styles.p}>Loyalty login</p>
                    {/*<p className={styles.title}>L o y a l t y&nbsp;&nbsp;&nbsp;&nbsp;P o r t a l</p>*/}
                    {
                        this.state.errorMsg && <div className={styles.content}>
                                <div>
                                    <Alert type={"error"} message={"Error"} description={this.state.errorMsg} />
                                </div>
                                <Form.Item>
                                    <BizexBtn btnType={config.BIZEX_BTN_TYPES.BLUE} htmlType="button" onClick={this.handleSignInAgain} className={styles.signInAgainBtn}>
                                        SIGN IN AGAIN
                                    </BizexBtn>
                                </Form.Item>
                            </div>
                    }
                    {
                        !this.state.errorMsg && <div className={styles.content}>
                            <Form autoComplete="off" onFinish={this.handleSubmitLogin} ref={this.formRef}>
                                <Form.Item name="username" rules={[{required: true, message: "Please input your email!"}]}>
                                    <Input
                                        autoComplete="off"
                                        prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        placeholder="Email"
                                        autoFocus={true}
                                        className={styles.inputs}
                                    />
                                </Form.Item>
                                <Form.Item name="password" rules={[{required: true, message: "Please input your password!"}]}>
                                    <Input.Password
                                        prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        placeholder="Password"
                                        className={styles.inputs}
                                    />
                                </Form.Item>
                                <Checkbox className={styles.rememberMe} checked={this.state.rememberMe} onChange={e => this.setState({rememberMe: !this.state.rememberMe})}>
                                    Remember me
                                </Checkbox>
                                <Form.Item>
                                    <BizexBtn btnType={config.BIZEX_BTN_TYPES.PINK} htmlType={"submit"} className={styles.submitBtn}>
                                        Sign In
                                    </BizexBtn>
                                    <BizexBtn btnType={config.BIZEX_BTN_TYPES.BLUE} onClick={this.clickForgotPassword} className={styles.forgetPwdBtn}>
                                        Forgot Password
                                    </BizexBtn>
                                </Form.Item>
                            </Form>
                            <div className={styles.spinWrap} >
                                <Spin spinning={this.state.loading}/>
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

export default withRouter(Login);
