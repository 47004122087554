import React from 'react';
import {Menu} from 'antd';
import {Link, withRouter} from "react-router-dom";
import config from "../../config";
import styles from "./index.module.less";
import {inject, observer} from "mobx-react";
import {logout} from "../../service";
import {message} from "../../lib";

@inject('stores')
@observer
class MyMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showChangePwd: false,
            loading: false,
            selected: null,
            overItem: null,
        };
        this.overItemOptions = {
            none: -1,
            myCards: 0,
            profile: 1,
            logout: 2,
        }
    }

    componentDidMount() {
        // console.log(this.props.history)
        this.setState({
            overItem: this.overItemOptions.none,
            selected: this.getSelected(),
        });
    }

    getSelected = () => {
        const {pathname} = this.props.location;
        if (pathname === config.ROUTES.MY_CARDS || pathname === config.ROUTES.PROFILE) {
            return pathname;
        }
        if (pathname.indexOf("/card/") === 0) {
            return config.ROUTES.MY_CARDS;
        }
        return null;
    };

    handleMenuClick = e => {
        this.setState({selected: e.key})
    };

    logout = async () => {
        try {
            const res = await logout();
            if (res.error) throw res.error;
            const {removeToken} = this.props.stores.AuthStore;
            removeToken();
            this.props.history.push("/login");
        } catch (e) {
            message.error(e.message)
        }
    };


    render() {
        const {selected, overItem} = this.state;
        return (
            <div className={styles.wrap}>

                <Menu
                    mode="inline"
                    selectedKeys={[this.state.selected]}
                    className={styles.menu}
                    onClick={this.handleMenuClick}
                >
                    <Menu.Item
                        className={styles.menuItemWrap}
                        key={config.ROUTES.MY_CARDS}
                        onPointerEnter={() => this.setState({overItem: this.overItemOptions.myCards})}
                        onPointerLeave={() => this.setState({overItem: this.overItemOptions.none})}
                    >
                        <Link to={config.ROUTES.MY_CARDS}>
                            <div
                                className={selected === config.ROUTES.MY_CARDS ? styles.menuItemSelected : overItem === this.overItemOptions.myCards ? styles.menuItemHover : styles.menuItem}
                            >
                                <img
                                    className={styles.menuImg}
                                    src={overItem === this.overItemOptions.myCards || selected === config.ROUTES.MY_CARDS ? require("../../img/icons/My cards white.png") : require("../../img/icons/My cards grey.png")}
                                    alt="My cards"
                                />
                                <p>My cards</p>
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        className={styles.menuItemWrap}
                        key={config.ROUTES.PROFILE}
                        onPointerEnter={() => this.setState({overItem: this.overItemOptions.profile})}
                        onPointerLeave={() => this.setState({overItem: this.overItemOptions.none})}
                    >
                        <Link to={config.ROUTES.PROFILE}>
                            <div
                                className={selected === config.ROUTES.PROFILE ? styles.menuItemSelected : overItem === this.overItemOptions.profile ? styles.menuItemHover : styles.menuItem}
                            >
                                <img
                                    className={styles.menuImg}
                                    src={overItem === this.overItemOptions.profile || selected === config.ROUTES.PROFILE ? require("../../img/icons/Profile white.png") : require("../../img/icons/Profile grey.png")}
                                    alt="Profile"
                                />
                                <p>Profile</p>
                            </div>
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item key="logout" onClick={this.logout}>*/}
                    {/*    <div className={selected === "logout" ? styles.menuItemSelected : styles.menuItem}>*/}
                    {/*        <img*/}
                    {/*            className={styles.menuImg}*/}
                    {/*            src={selected === "logout" ? require("../img/icons/Profile white.png") : require("../img/icons/Profile grey.png")}*/}
                    {/*            alt="Profile"*/}
                    {/*        />*/}
                    {/*        <p>Profile</p>*/}
                    {/*    </div>*/}
                    {/*</Menu.Item>*/}
                </Menu>

                <div
                    className={styles.logout}
                    onClick={this.logout}
                    onPointerEnter={() => this.setState({overItem: this.overItemOptions.logout})}
                    onPointerLeave={() => this.setState({overItem: this.overItemOptions.none})}
                >
                    <img
                        className={styles.menuImg}
                        src={overItem === this.overItemOptions.logout ? require("../../img/icons/Log out white.png") : require("../../img/icons/Log out grey.png")}
                        alt="hide"
                    />
                    <p>Log out</p>
                </div>
                {/*<div className={styles.hideBtnWrap} onClick={this.props.hideMenu} title="Hide Menu">*/}
                {/*    <img*/}
                {/*        className={styles.hideBtn}*/}
                {/*        src={require("../img/icons/Hide.png")}*/}
                {/*        alt="hide"*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default withRouter(MyMenu);
