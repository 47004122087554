import React from 'react';
import {Redirect, withRouter} from "react-router";
import MyMenu from "../../components/MyMenu";
import {inject, observer} from "mobx-react";
import {getCurrentLoyaltyUser} from "../../service";
import {User} from "../../entities";
import {message} from "../../lib";
import {Spin} from "antd";
import config from "../../config";
import {Layout} from "antd";
import styles from "./index.module.less";
import UserOutlined from "@ant-design/icons/es/icons/UserOutlined";
import moment from "moment";

const { Header, Content, Sider } = Layout;

@inject('stores')
@observer
class Main extends React.Component {
    state = {
        loading: true,
        errorMsg: "",
        showMenu: true,
    };

    colorOptions = {
        s: 40,
        l: 80,
    };

    componentDidMount = async () => {
        try {
            let res = await getCurrentLoyaltyUser();
            if (res.code === 401 && !this.props.stores.AuthStore.isLogin) return;
            if (res.error) throw res.error;
            const {data} = res;
            if (data && data.userinfo) {
                const user = User.buildFromData(data.userinfo);
                this.props.stores.AuthStore.setUser(user);
            }
            if (data && data.loyaltycompanyinfo) {
                let companyList = data.loyaltycompanyinfo;
                for (const index in companyList) {
                    if (!companyList[index].logo) {
                        companyList[index].color = this.getRandomColor(index);
                    }
                }
                this.props.stores.CardStore.setCompanyList(companyList);
            }
        } catch (e) {
            if (e.code !== 401 || !this.props.stores.AuthStore.isLogin)
            message.error(e.message)
        } finally {
            this.setState({loading: false})
        }
    };

    getRandomColor = (index) => {
        const baseH = isNaN(parseInt(index)) ? Math.floor(Math.random()*360) : parseInt(index);
        const h = (Math.random() * 360 + baseH * 49) % 360;
        return `hsl(${h}, ${this.colorOptions.s}%, ${this.colorOptions.l}%)`;
    };

    showMenu = () => {
        const {showMenu} = this.state;
        this.setState({showMenu: !showMenu})
    };

    render() {
        const {loading} = this.state;
        const {currentUser} = this.props.stores.AuthStore;
        // console.log("Refresh Main page: ", this.props.stores.AuthStore.isLogin.toString())

        if (loading) {
            return (
                <Spin className={styles.spin} tip={"System Loading"}/>
            )
        }
        if (this.props.stores.AuthStore.isLogin) {
            // console.log("rerender header", this.props.stores.CardStore.currentCard)
            return (
                <Layout className={styles.main}>
                    <Header className={styles.header}>
                        <img
                            src={require("../../img/pink_bg_logo.jpg")}
                            alt="logo"
                            className={styles.logoImg}
                            onClick={this.showMenu}
                        />

                        <span className={styles.headerText}>
                            {this.props.stores.CardStore.currentCard ? `${this.props.stores.CardStore.currentCard.loyalty_company_name} ${this.props.stores.CardStore.currentCard.loyalty_number}` : ""}
                            {/*{*/}
                            {/*    this.props.stores.CardStore.currentCard ?*/}
                            {/*    <span>*/}
                            {/*        /!*<BizexBtn title="Go back" onClick={this.props.history.goBack} btnType={config.BIZEX_BTN_TYPES.GREY_GHOST} className={styles.backBtn}>*!/*/}
                            {/*        /!*    <RollbackOutlined />*!/*/}
                            {/*        /!*</BizexBtn>*!/*/}
                            {/*        {this.props.stores.CardStore.currentCard.loyalty_company_name} {this.props.stores.CardStore.currentCard.loyalty_number}*/}
                            {/*    </span> */}
                            {/*    : ""*/}
                            {/*}*/}
                        </span>
                        <div className={styles.headerRight}>
                            {
                                currentUser.avatar ?
                                    <div className={styles.avatar} style={{backgroundImage: `url(${currentUser.avatar})`}}/>
                                    :
                                    <UserOutlined className={styles.avatar}/>
                            }
                            <span className={styles.welcome}>
                                {
                                    "Welcome back, " + (currentUser && currentUser.first_name && currentUser.last_name ?
                                    currentUser.first_name + " " + currentUser.last_name : "")
                                }
                            </span>
                            <span className={styles.lastLogin}>
                                {
                                    currentUser.last_login_time && moment(currentUser.last_login_time, "YYYY-MM-DD HH:mm:ss Z").format("[Last logged in:] DD/MM/YYYY [at] HH:mm")
                                }
                            </span>
                        </div>
                    </Header>
                    <Layout>
                        {
                            this.state.showMenu && <Sider width={70} className={styles.sider}>
                                <MyMenu hideMenu={this.showMenu}/>
                            </Sider>
                        }

                        <Layout>
                            <Content>
                                {this.props.children}
                            </Content>
                        </Layout>
                    </Layout>

                </Layout>
            )
        }

        return <Redirect to={config.ROUTES.LOGIN}/>

    }
}

export default withRouter(Main);
