import {action, computed, observable} from "mobx";
import {logout} from "../service";
import {CacheStorage,} from '../lib';
// import * as R from "ramda";
// import _ from 'lodash';
// import history from '../MyMenu/history';
import config from "../config";

export default class AuthStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.setToken(this.loadToken())
    }

    @observable user = null;
    @observable updated_at = null;

    @action update = () => {
        this.updated_at = new Date();
    };

    @action setUser = user => {
        this.user = user;
    };
    @action removeUser = () => {this.user = null };

    @computed get currentUser() {
        return this.user;
    }

    @computed get isLogin() {
        return !!this.user;
    }

    @action logout = () => {
        logout().then(async () => {
            // console.log("from AuthStore: Logout Done!!!")
            this.removeUser();
            this.removeToken();
            // history.push(config.ROUTES.LOGIN);
        })
    };

    @action logoutLocally = () => {
        this.removeUser();
        this.removeToken();
    };

    @observable token = '';

    @action setToken = token => {
        this.token = token;
    };

    @action saveToken = token => {
        CacheStorage.setItem(config.TOKEN_SYMBOL, token);
    };

    @computed get getToken () {
        return this.token;
    }

    @action loadToken = () => {
        return CacheStorage.getItem(config.TOKEN_SYMBOL);
    };

    @action removeToken = () => {
        this.token = '';
        CacheStorage.removeItem(config.TOKEN_SYMBOL);
    };

    @action setCardColor = (cardId, color) => {
        const cards = this.user.card_lines;
        if (cards) {
            for (const card of cards) {
                if (card.id === cardId) {
                    card.color = color;
                    break;
                }
            }
        }
    };

}
