import React from "react";
import styles from "./index.module.less";
import {Col, Form, Input, Progress, Row, Spin} from "antd";
import {editLoyaltyCustomer} from "../../../service";
import {message} from "../../../lib";
import config from "../../../config";
import {inject, observer} from "mobx-react";
import BizexBtn from "../../../components/BizexBtn";
import {withRouter} from "react-router";

@inject('stores')
@observer
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            color: "#ff0000",
            percentage: 0,
        };
        this.formRef = React.createRef();
    }

    handleSubmit = value => {
        this.setState({loading: true}, async () => {
            try {
                const {passwd, pwdConfirm} = value;
                if (passwd !== pwdConfirm) {
                    throw new Error("Two times password input are not the same");
                }
                let res = await editLoyaltyCustomer({passwd});
                if (res.error) throw res.error;
                this.setState({showChangePwd: false});
                message.success("Reset success, please login again");
                this.props.stores.AuthStore.logout();
                return this.props.history.push(config.ROUTES.LOGIN);
            } catch (e) {
                message.error(e.message);
                this.setState({loading: false});
                this.formRef.current.resetFields();
            }
        })
    };

    getPasswordStrength = text => {
        const valList = text.split("");
        let num = 0, upper = 0, lower = 0, others = 0;  //  number, upper case, lower case, others
        for (const letter of valList) {
            const code = letter.charCodeAt(0);
            if (code >= 48 && code <= 57) {
                num = 1;
            } else if (code >= 65 && code <= 90) {
                upper = 1;
            } else if (code >= 97 && code <= 122) {
                lower = 1;
            } else {
                others = 1;
            }
        }
        const sum = num + upper + lower + others;
        let percentage = 0, color = "";
        if (sum === 4) {
            percentage = 100;
            color = "#52c41a";
        } else if (sum === 3) {
            percentage = 75;
            color = "#1890ff";
        } else if (sum === 2) {
            percentage = 50;
            color = "#ffa300";
        } else if (sum === 1) {
            percentage = 25;
            color = "#ff0000";
        } else {
            percentage = 0;
            color = "#ff0000";
        }
        this.setState({percentage, color});
    };

    handleCancel = () => {
        this.formRef.current.resetFields();
        this.getPasswordStrength("");
    };

    render() {
        return (
            <div className={styles.wrap}>
                <div>
                    <Spin className={styles.spin} spinning={this.state.loading}/>
                </div>
                <h1 className={styles.title}>Reset Password</h1>
                <div className={styles.formWrap}>
                    <Form
                        onFinish={this.handleSubmit}
                        labelCol={{xs: {span: 24}, sm: {span: 24}, lg: {span: 24}, xl: {span: 8}, xxl: {span: 8} }}
                        wrapperCol={{xs: {span: 24}, sm: {span: 24}, lg: {span: 24}, xl: {span: 16}, xxl: {span: 16}}}
                        ref={this.formRef}
                        className={styles.form}
                        initialValues={{passwd: "", pwdConfirm: ""}}
                        colon={false}
                    >
                        <Form.Item
                            className={styles.formItem}
                            label={<span className={styles.label}>New Password:</span>}
                            name="passwd"
                            rules={[{required: true, message: "Require new password"}]}
                        >
                            <Input type="password" placeholder={"New password"} onChange={e => this.getPasswordStrength(e.target.value)}/>
                        </Form.Item>
                        <Form.Item
                            label={<span/>}
                            className={styles.formItem}
                        >
                            <Row>
                                <Col span={9}>
                                    <span>Password Strength:</span>
                                </Col>
                                <Col span={15}>
                                    <Progress percent={this.state.percentage} strokeColor={this.state.color} size="small" showInfo={false}/>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            className={styles.formItem}
                            label={<span className={styles.label}>Password Confirm:</span>}
                            name="pwdConfirm"
                            rules={[{required: true, message: "Require password confirm"}]}
                        >
                            <Input type="password" placeholder={"Password confirm"}/>
                        </Form.Item>
                        <Form.Item
                            label={<span/>}
                            className={styles.formItem}
                        >
                            <BizexBtn
                                className={styles.button}
                                btnType={config.BIZEX_BTN_TYPES.BLUE_GHOST}
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </BizexBtn>
                            <span className={styles.btnSpace}/>
                            <BizexBtn
                                className={styles.button}
                                btnType={config.BIZEX_BTN_TYPES.PINK}
                                htmlType={"submit"}
                            >
                                Submit
                            </BizexBtn>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

export default withRouter(ResetPassword);
